import actions from "./actions";

const premiumTypes = [];
const competitions = [];
const countries = [];
const nextGames = [];
const seasons = [];
const globalSearchResult = { players: [], teams: [] };
const groups = [];
const teams = [];
const games = [];
const searching = false;
const players = [];
const bestLineup = [];
const standings = [];
const outstandingDashboard = [];
const loadingLineup = false;
const game = {
  home: {
    players: [],
    color: { bg1: "#ff0000", bg2: "#ff0000", text: "#FFFFFF" },
  },
  away: {
    players: [],
    color: { bg1: "#ffffff", bg2: "#ffffff", text: "#000000" },
  },
};
const rounds = [];
const career = [];
const loading = false;
const error = false;
const selected_type = null;
const season_player_stat = 0;
const filteredCompetitions = [];
const favouriteCompetitions = [];
const favouritePlayers = [];
const favouriteTeams = [];
const selectedSeason = [];
const selectedCompetition = [];
const selectedFederation = [];
const selectedGroup = [];
const selectedTeam = {};
const selectedGame = {};
const selectedPlayer = {};
const selectedCountry = {};
const playerGames = [];
const playerDetail = [];
const listPlayerSearch = [];
const teamPlayers = [];
const competitionPlayers = [];
const competitionStats = [];
const filteredPlayerRadar = { labels: [], datasets: [] };
const playerStats = [];
const originalStats = [];
const playerRadar = { labels: [], datasets: [] };
const apexChart = {
  series: [],
  options: {
    chart: {
      height: 850,
      type: "radar",
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1,
      },
    },
    title: {
      text: "",
    },
    stroke: {
      width: 0,
    },
    fill: {
      opacity: 0.4,
    },
    markers: {
      size: 0,
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: [],
    },
  },
};

const radarEchartOptions = {
  backgroundColor: "#33b0e520",
  toolbox: {
    show: true,
    feature: {
      mark: {
        show: true,
      },
      restore: {
        show: true,
        title: "Restore",
      },
      saveAsImage: {
        show: true,
        title: "Save",
      },
    },
  },
  graphic: [
    {
      type: "group",
      rotation: Math.PI / 4,
      bounding: "raw",
      right: 70,
      bottom: 50,
      z: 100,
      children: [
        {
          type: "rect",
          left: "center",
          top: "center",
          z: 100,
          shape: {
            width: 500,
            height: 40,
          },
          style: {
            fill: "#f02d4f",
          },
        },
        {
          type: "text",
          left: "center",
          top: "center",
          z: 100,
          style: {
            fill: "#fff",
            text: "Face 2 Face",
            font: "bold 22px Microsoft YaHei",
          },
        },
      ],
    },
  ],
  title: {
    text: "Face to Face",
    left: "center",
    top: 20,
    textStyle: {
      color: "#000",
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    left: "center",
    data: [], //Players
  },
  radar: [
    {
      indicator: [
        //{text: 'Velocidad', max: 15},
      ],
      radius: 200,
      center: ["50%", "50%"],
    },
  ],
  series: [
    {
      type: "radar",
      tooltip: {
        trigger: "item",
      },
      selectedMode: "multiple",
      radarIndex: 0,
      areaStyle: {},
      data: [],
    },
  ],
};

const initState = {
  premiumTypes,
  competitions,
  selectedFederation,
  countries,
  selected_type,
  seasons,
  filteredCompetitions,
  selectedSeason,
  selectedCompetition,
  selectedGroup,
  groups,
  games,
  teams,
  players,
  bestLineup,
  game,
  rounds,
  nextGames,
  selectedTeam,
  selectedGame,
  standings,
  selectedPlayer,
  playerGames,
  selectedCountry,
  favouriteCompetitions,
  loading,
  error,
  teamPlayers,
  favouritePlayers,
  favouriteTeams,
  playerDetail,
  competitionPlayers,
  playerStats,
  playerRadar,
  apexChart,
  filteredPlayerRadar,
  originalStats,
  listPlayerSearch,
  season_player_stat,
  radarEchartOptions,
  outstandingDashboard,
  globalSearchResult,
  loadingLineup,
  career,
  competitionStats,
  searching,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GLOBAL_SEARCH:
      return {
        ...state,
        loading: true,
      };
    case actions.GLOBAL_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        globalSearchResult: action.payload.globalSearchResult,
      };
    case actions.GET_PLAYER_CAREER:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PLAYER_CAREER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        career: action.career,
      };
    case actions.GET_NEXT_PLAYER_GAMES:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_NEXT_PLAYER_GAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        nextGames: action.nextGames,
      };
    case actions.GET_PREMIUM_TYPES:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PREMIUM_BEST_LINEUP:
      return {
        ...state,
        loadingLineup: true,
      };
    case actions.GET_PREMIUM_BEST_LINEUP_SUCCESS:
      return {
        ...state,
        bestLineup: action.bestLineup,
        loadingLineup: false,
      };
    case actions.CHANGE_TEAM_COLOR:
      return {
        ...state,
        loading: false,
        error: false,
        game: action.game,
      };
    case actions.SET_MANUAL_PLAYER:
      return {
        ...state,
        loading: false,
        error: false,
        selectedPlayer: action.payload.selectedPlayer,
      };
    case actions.GET_PREMIUM_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        premiumTypes: action.premiumTypes,
      };
    case actions.GET_FAVOURITE_COMPETITIONS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case actions.GET_FAVOURITE_COMPETITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        favouriteCompetitions: action.favouriteCompetitions,
      };
    case actions.SET_FAVOURITE_COMPETITION:
      return {
        ...state,
        loading: true,
        error: false,
        groups: action.payload.groups,
      };

    case actions.SET_FAVOURITE_COMPETITION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case actions.GET_FAVOURITE_PLAYERS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case actions.GET_FAVOURITE_PLAYERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        favouritePlayers: action.favouritePlayers,
      };
    case actions.SET_FAVOURITE_PLAYER:
      return {
        ...state,
        loading: true,
        error: false,
        teamPlayers: action.payload.teamPlayers,
      };
    case actions.SET_FAVOURITE_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case actions.GET_FAVOURITE_TEAMS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case actions.GET_FAVOURITE_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        favouriteTeams: action.favouriteTeams,
      };
    case actions.SET_FAVOURITE_TEAM:
      return {
        ...state,
        loading: true,
        error: false,
        selectedTeam: action.payload.selectedTeam,
      };
    case actions.SET_FAVOURITE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case actions.GET_PREMIUM_OUTSTANDINGDASHBOARD:
      return {
        ...state,
        loading: true,
        outstandingDashboard: [],
      };
    case actions.GET_PREMIUM_OUTSTANDINGDASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        outstandingDashboard: action.payload.outstandingDashboard,
      };
    case actions.CLEAN_STATS:
      return {
        ...state,
        loading: false,
        radarEchartOptions: action.payload.radarEchartOptions,
      };
    case actions.GET_PREMIUM_COMPETITIONS:
      return {
        ...state,
        loading: true,
        selectedFederation: action.payload.selected_type,
        selected_type: action.payload.selected_type,
        radarEchartOptions: action.payload.radarEchartOptions,
      };
    case actions.GET_PREMIUM_COMPETITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        competitions: action.competitions,
        filteredCompetitions: [],
        countries: action.countries,
        seasons: [],
      };
    case actions.GET_COMPETITION_GROUPS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PREMIUM_GROUPS:
      return {
        ...state,
        loading: true,
        selectedCom: action.payload.selectedCom,
      };
    case actions.GET_PREMIUM_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        groups: action.groups,
      };
    case actions.GET_PREMIUM_STANDINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        standings: action.standings,
      };
    case actions.GET_PREMIUM_STANDINGS:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_PREMIUM_COMPETITION_PLAYERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        competitionPlayers: action.competitionPlayers,
      };
    case actions.GET_PREMIUM_COMPETITION_PLAYERS:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_PREMIUM_PLAYER_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        playerStats: action.payload.playerStats,
        radarEchartOptions: action.payload.radarEchartOptions,
        originalStats: action.payload.originalStats,
      };
    case actions.CHANGE_ACTIVE_PLAYER_STAT:
      return {
        ...state,
        loading: false,
        error: false,
        playerStats: action.payload.playerStats,
        filteredPlayerRadar: action.payload.filteredPlayerRadar,
      };
    case actions.SEARCH_PLAYER:
      return {
        ...state,
        loading: false,
        searching: true,
        error: false,
      };
    case actions.SEARCH_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        searching: false,
        error: false,
        listPlayerSearch: action.payload.playerList,
      };
    case actions.REMOVE_PLAYER_STAT:
      return {
        ...state,
        loading: false,
        error: false,
        playerStats: action.payload.playerStats,
        playerRadar: action.payload.playerRadar,
        filteredPlayerRadar: action.payload.filteredPlayerRadar,
      };
    case actions.GET_PREMIUM_PLAYER_STATS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PREMIUM_PLAYER_STATS_POSITION:
      return {
        ...state,
        loading: true,
        season_player_stat: action.payload.season,
      };
    case actions.GET_PREMIUM_GAMES:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case actions.GET_PREMIUM_GAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        games: action.games,
      };

    case actions.GET_PREMIUM_PLAYER_GAMESV2:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case actions.GET_PREMIUM_PLAYER_GAMES:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case actions.GET_PREMIUM_PLAYER_GAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        playerGames: action.playerGames,
      };
    case actions.GET_PREMIUM_PLAYER_DETAIL:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case actions.GET_PREMIUM_PLAYER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        playerDetail: action.playerDetail,
        selectedPlayer: action.playerDetail[0],
      };
    case actions.GET_PREMIUM_GAME_REPORT:
      return {
        ...state,
        loading: true,
        selectedGame: action.payload.selectedGame,
      };
    case actions.GET_PREMIUM_GAME_REPORT_SUCCESS:
      return {
        ...state,
        loading: true,
        game: action.game,
      };
    case actions.GET_PREMIUM_TEAM_PLAYERS:
      return {
        ...state,
        loading: false,
        error: false,
        selectedTeam: action.payload.team,
      };
    case actions.GET_PREMIUM_TEAM_PLAYERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        teamPlayers: action.teamPlayers,
      };
    case actions.SELECT_SEASON:
      return {
        ...state,
        loading: true,
        selectedSeason: action.payload.selectedSeason,
      };
    case actions.SELECT_GROUP:
      return {
        ...state,
        loading: true,
        selectedGroup: action.payload.selectedGroup,
      };
    case actions.SELECT_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload.selectedCountry,
        filteredCompetitions: action.payload.filteredCompetitions,
      };
    case actions.SELECT_COMPETITION:
      return {
        ...state,
        selectedCompetition: action.payload.selectedCompetition,
        selectedSeason: [],
        selectedGroup: [],
        seasons: action.payload.seasons,
      };
    case actions.SELECT_PLAYER:
      return {
        ...state,
        loading: true,
        selectedPlayer: action.payload.selectedPlayer,
      };
    case actions.API_PREMIUM_ERROR:
      return initState;
    default:
      return state;
  }
}
