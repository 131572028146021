export default {
  siteName: "Pro Club",
  clubName: "C.D. Lugo S.A.D.",
  logo: "lugo.png",
  bg: "lugobg.jpg",
  siteIcon: "ion-eye",
  footerText: `ProClub @ ${new Date().getFullYear()} Created by GoalScout, Inc . V1.0 R.C.16`,
  enableAnimatedRoute: false,
  apiUrl: "https://api2.cdlugo.goalscout.es/api/",
  baseImg: "https://static.goalscout.es/images/",
  google: {
    analyticsKey: "UA-xxxxxxxxx-1",
  },
  dashboard: "/dashboard",
};

//logo: "lugo.png",
//bg: "lugobg.jpg",
//apiUrl: "https://api2.cdlugo.goalscout.es/api/",

//logo: "albacete.png",
//bg: "albacetebg.jpg",
// apiUrl: "https://api2.albacete.goalscout.es/api/",
