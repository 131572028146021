const actions = {
  GET_SESSIONS: "GET_SESSIONS",
  DELETE_SESSION: "DELETE_SESSION",
  GET_SESSIONS_SUCCESS: "GET_SESSIONS_SUCCESS",
  SAVE_SESSION: "SAVE_SESSION",
  SAVE_SESSION_PLAYER: "SAVE_SESSION_PLAYER",
  SAVE_SESSION_SUCCESS: "SAVE_SESSION_SUCCESS",
  GET_SESSION_PLAYERS: "GET_SESSION_PLAYERS",
  GET_SESSION_PLAYERS_SUCCESS: "GET_SESSION_PLAYERS_SUCCESS",
  GET_BODYPOINTS: "GET_BODYPOINTS",
  GET_BODYPOINTS_SUCCESS: "GET_BODYPOINTS_SUCCESS",
  GET_WELLNESS_QUESTIONS: "GET_WELLNESS_QUESTIONS",
  GET_WELLNESS_QUESTIONS_SUCCESS: "GET_WELLNESS_QUESTIONS_SUCCESS",
  SAVE_WELLNESS_ANSWERS: "SAVE_WELLNESS_ANSWER",
  SAVE_WELLNESS_ANSWERS_SUCCESS: "SAVE_WELLNESS_ANSWERS_SUCCESS",
  GET_WELLNESS_ANSWERS: "GET_WELLNESS_ANSWERS",
  GET_WELLNESS_ANSWERS_SUCCESS: "GET_WELLNESS_ANSWERS_SUCCESS",
  GET_WELLNESS_HURTS: "GET_WELLNESS_HURTS",
  GET_WELLNESS_HURTS_SUCCESS: "GET_WELLNESS_HURTS_SUCCESS",
  SAVE_WELLNESS_HURT: "SAVE_WELLNESS_HURT",
  SAVE_WELLNESS_HURT_SUCCESS: "SAVE_WELLNESS_HURT_SUCCESS",
  GET_FULL_WELLNESS_HURTS: "GET_FULL_WELLNESS_HURTS",
  GET_FULL_WELLNESS_HURTS_SUCCESS: "GET_FULL_WELLNESS_HURTS_SUCCESS",
  GET_FULL_WELLNESS_ANSWERS: "GET_FULL_WELLNESS_ANSWERS",
  GET_FULL_WELLNESS_ANSWERS_SUCCESS: "GET_FULL_WELLNESS_ANSWERS_SUCCESS",
  GET_WELLNESS_ANSWERS_DATE_RANGE: "GET_WELLNESS_ANSWERS_DATE_RANGE",
  GET_WELLNESS_ANSWERS_DATE_RANGE_SUCCESS:
    "GET_WELLNESS_ANSWERS_DATE_RANGE_SUCCESS",
  API_WELLNESS_ERROR: "API_PREMIUM_ERROR",
  getBodyPoints: () => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_BODYPOINTS,
      });
    };
  },
  getBodyPointsSuccess: (bodyPoints) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_BODYPOINTS_SUCCESS,
        payload: { bodyPoints },
      });
    };
  },
  getQuestions: () => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_WELLNESS_QUESTIONS,
        payload: {},
      });
    };
  },
  getQuestionsSuccess: (questions) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_WELLNESS_QUESTIONS_SUCCESS,
        payload: { questions },
      });
    };
  },
  getAnswers: (uid) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_WELLNESS_ANSWERS,
        payload: { uid },
      });
    };
  },
  saveAnswer: (answer) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_WELLNESS_ANSWERS,
        payload: { answer },
      });
    };
  },
  getAnswersSuccess: (answers) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_WELLNESS_ANSWERS_SUCCESS,
        payload: { answers },
      });
    };
  },
  getFullAnswers: (uid) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_FULL_WELLNESS_ANSWERS,
        payload: { uid },
      });
    };
  },
  getFullAnswersSuccess: (answers) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_FULL_WELLNESS_ANSWERS_SUCCESS,
        payload: { answers },
      });
    };
  },
  getHurts: (uid) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_WELLNESS_HURTS,
        payload: { uid },
      });
    };
  },
  getHurtsSuccess: (hurts) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_WELLNESS_HURTS_SUCCESS,
        payload: { hurts },
      });
    };
  },
  getFullHurts: (uid) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_FULL_WELLNESS_HURTS,
        payload: { uid },
      });
    };
  },
  getFullHurtsSuccess: (hurts) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_FULL_WELLNESS_HURTS_SUCCESS,
        payload: { hurts },
      });
    };
  },
  saveHurt: (hurt) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_WELLNESS_HURT,
        payload: { hurt },
      });
    };
  },
  getSessions: (date) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_SESSIONS,
        payload: { date },
      });
    };
  },
  getSessionsSuccess: (sessions) => ({
    type: actions.GET_SESSIONS_SUCCESS,
    sessions,
  }),
  saveSession: (session) => {
    return (dispatch, getState) => {
      delete session.key;
      dispatch({
        type: actions.SAVE_SESSION,
        payload: { session },
      });
    };
  },
  deleteSession: (session) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.DELETE_SESSION,
        payload: { session },
      });
    };
  },
  saveSessionSuccess: (uid) => ({
    type: actions.SAVE_SESSION_SUCCESS,
    payload: { uid },
  }),
  getSessionPlayers: (uid) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_SESSION_PLAYERS,
        payload: { uid },
      });
    };
  },
  getSessionPlayersSuccess: (players) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_SESSION_PLAYERS_SUCCESS,
        payload: { players },
      });
    };
  },
  toggleSessionPlayer: (player) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_SESSION_PLAYER,
        payload: { player },
      });
    };
  },

  getAnswersByDateRange: (dates) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_WELLNESS_ANSWERS_DATE_RANGE,
        payload: { dates },
      });
    };
  },
  getAnswersByDateRangeSuccess: (answersRange) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_WELLNESS_ANSWERS_DATE_RANGE_SUCCESS,
        payload: { answersRange },
      });
    };
  },

  getAPIError: () => ({
    type: actions.API_WELLNESS_ERROR,
  }),
};

export default actions;
