const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  JWT_LOGIN_REQUEST: "JWT_LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  CHANGE_PASS: "CHANGE_PASS",
  CHANGE_PASS_SUCCESS: "CHANGE_PASS_SUCCESS",
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (token = false) => ({
    type: actions.LOGIN_REQUEST,
    payload: { token },
  }),
  changePass: (data) => ({
    type: actions.CHANGE_PASS,
    payload: { data },
  }),
  changePassSuccess: (result) => ({
    type: actions.CHANGE_PASS_SUCCESS,
    payload: { result },
  }),
  jwtLogin: (history, userInfo) => ({
    type: actions.JWT_LOGIN_REQUEST,
    payload: { userInfo },
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;
