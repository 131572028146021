import defaultTheme from "./default";
import customTheme from "./custom";
import lugoTheme from "./lugo";

const themes = {
  defaultTheme,
  customTheme,
  lugoTheme,
};

export const themeConfig = {
  topbar: "lugoTheme",
  sidebar: "lugoTheme",
  layout: "lugoTheme",
  theme: "lugoTheme",
};

export default themes;
