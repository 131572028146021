export const PUBLIC_ROUTE = {
  LANDING: "/",
  SIGN_IN: "/signin",
  AGENT_SIGN_IN: "/agent/signin",
  SIGN_UP: "/signup",
  FORGET_PASSWORD: "/forgotpassword",
  RESET_PASSWORD: "/resetpassword",
  PAGE_404: "/404",
  PAGE_500: "/500",
  AUTH0_CALLBACK: "/auth0loginCallback",
};

export const PRIVATE_ROUTE = {
  DASHBOARD: "/",
  COMPETITIONS: "/competitions",
  WELLNESS: "/wellness",
  WELLNESSADMIN: "WellnessAdmin",
  WELLNESSSTATS: "WellnessStats",
  AGENT_SIGN_IN: "/agencies",
  SWIPER_SLIDER: "/swiperslider",
};
