import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import {
  fetchVideoPlayerEventsTypes,
  fetchVideoPlayerEventsBySelector,
  fetchVideoPlayerEventsByGame,
  fetchVideoMatch,
  fetchGetPlayerSkills,
  fetchGetPlayersPositions,
  fetchUserPlayersReports,
  postPlayerReport2,
  deletePlayerReport,
  fetchGetPlayersLevels,
  fetchSelectedPlayerReport,
  fetchSelectedPlayerReportsHistory,
  fetchPlayerReportsFiltered,
  fetchReportsCountByUser,
  fetchReportsCountByCom,
  fetchReportsCountByLevel,
  fetchReportsCountByPosition,
  updateShadowTeam,
  getUserShadowTeams,
  updatePlayerRanking,
  getShadowTeamPlayers,
  fetchCurrentSeason,
  getShadowTeamStaff,
  updateRankingStaff,
  saveAgency,
  saveAgent,
  savePlayerAgent,
  fetchAgents,
  fetchAgencyPlayers,
  fetchAllAgenciesPlayers,
  fetchAgencies,
  fetchClubTeams,
  fetchPlayerAgencyStatus,
  deleteAgency,
  deleteShadowPlayer,
  deleteShadowStaff,
} from "../../library/helpers/scoutApi";
import { createBrowserHistory } from "history";
import { clearToken } from "@iso/lib/helpers/utility";
import clone from "clone";

const history = createBrowserHistory({ forceRefresh: true });

// Videos

export function* getVideoSelectors() {
  yield takeEvery("GET_VIDEO_SELECTORS", function* () {
    try {
      const selectors = yield call(fetchVideoPlayerEventsTypes);

      if (!selectors.error && selectors.response) {
        yield put(actions.getVideoSelectorsSuccess(selectors.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getVideoPlayerBySelector() {
  yield takeEvery("GET_VIDEO_PLAYER_SELECTOR", function* ({ payload }) {
    try {
      const videos = yield call(
        fetchVideoPlayerEventsBySelector,
        payload.uid_fed,
        payload.uid_pla,
        payload.selector
      );

      if (!videos.error && videos.response) {
        yield put(actions.getVideosSuccess(videos.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getScoutSkills() {
  yield takeEvery("GET_SCOUT_SKILLS", function* () {
    try {
      const skills = yield call(fetchGetPlayerSkills);

      if (!skills.error && skills.response) {
        const mapSkills = skills.response.map((s) => {
          if (s.positions && s.positions !== null)
            s.positions = s.positions.split(",").map((e) => parseInt(e));
          else if (s.pos2 && s.pos2 !== null)
            s.positions = s.pos2.split(",").map((e) => parseInt(e));
          return s;
        });
        yield put(actions.getScoutSkillsSuccess(mapSkills));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getScoutPlayersPositions() {
  yield takeEvery("GET_SCOUT_PLAYER_POSITIONS", function* () {
    try {
      const positions = yield call(fetchGetPlayersPositions);

      if (!positions.error && positions.response) {
        yield put(actions.getScoutPlayerPositionsSuccess(positions.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getScoutPlayersLevels() {
  yield takeEvery(actions.GET_SCOUT_PLAYER_LEVELS, function* () {
    try {
      const response = yield call(fetchGetPlayersLevels);

      if (!response.error && response.response) {
        yield put(actions.getScoutPlayerLevelsSuccess(response.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getCurrentSeason() {
  yield takeEvery("GET_CURRENT_SEASON", function* () {
    try {
      const seasons = yield call(fetchCurrentSeason);
      if (!seasons.error && seasons.response) {
        yield put(actions.getCurrentSeasonSuccess(seasons.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getScoutReports() {
  yield takeEvery("GET_SCOUT_PLAYER_REPORTS", function* () {
    try {
      const reports = yield call(fetchUserPlayersReports);
      if (!reports.error && reports.response) {
        yield put(actions.getScoutPlayersReportsSuccess(reports.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* deleteScoutShadowPlayer() {
  yield takeEvery(actions.DELETE_SHADOW_PLAYER, function* ({ payload }) {
    try {
      const result = yield call(
        deleteShadowPlayer,
        payload.pla.uid_pla,
        payload.pla.uid_ranking
      );
      if (!result.error && result.response) {
        yield put({
          type: actions.GET_SHADOW_TEAM_PLAYERS,
          payload: { uid: payload.pla.uid_ranking },
        });
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* deleteScoutShadowStaff() {
  yield takeEvery(actions.DELETE_SHADOW_STAFF, function* ({ payload }) {
    try {
      const result = yield call(
        deleteShadowStaff,
        payload.staff.uid,
        payload.staff.uid_ranking
      );
      if (!result.error && result.response) {
        yield put({
          type: actions.GET_SHADOW_TEAM_STAFF,
          payload: { uid: payload.staff.uid_ranking },
        });
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* deleteScoutAgency() {
  yield takeEvery(actions.DELETE_AGENCY, function* ({ payload }) {
    try {
      const result = yield call(deleteAgency, payload.uid);
      if (!result.error && result.response) {
        yield put({ type: actions.GET_AGENCIES });
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getScoutPlayerReport() {
  yield takeEvery(
    actions.GET_SCOUT_SELECTED_PLAYER_REPORT,
    function* ({ payload }) {
      try {
        const result = yield call(fetchSelectedPlayerReport, payload.uid);
        if (!result.error && result.response) {
          yield put(actions.getScoutPlayerReportSuccess(result.response));
        } else {
          yield put({ type: actions.API_SCOUT_ERROR });
        }
      } catch (error) {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    }
  );
}

export function* getScoutPlayerReportsHistory() {
  yield takeEvery(actions.GET_PLAYER_REPORT_HISTORY, function* ({ payload }) {
    try {
      const result = yield call(fetchSelectedPlayerReportsHistory, payload.uid);
      if (!result.error && result.response) {
        yield put(actions.getScoutPlayerReportHistorySuccess(result.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* deleteScoutReport() {
  yield takeEvery("DELETE_PLAYER_REPORT", function* ({ payload }) {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(deletePlayerReport, payload.uid);
      if (!result.error && result.response) {
        yield put(actions.getScoutPlayersReports());
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}
export function* saveScoutReport() {
  yield takeEvery("SAVE_PLAYER_REPORT", function* ({ payload }) {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(postPlayerReport2, {
        report: payload.currentReport,
        skills: payload.skills,
      });
      if (!result.error && result.response) {
        let currentReport = clone(payload.currentReport);
        if (result.response.uid) {
          currentReport.uid = result.response.uid;
        }

        yield put(actions.saveScoutReportSuccess(currentReport));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getScoutRankings() {
  yield takeEvery("GET_USER_SHADOW_TEAMS", function* () {
    try {
      const shadowTeams = yield call(getUserShadowTeams);
      if (!shadowTeams.error && shadowTeams.response) {
        yield put(actions.getUserShadowTeamsSuccess(shadowTeams.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getScoutPlayerAgencyStatus() {
  yield takeEvery("GET_PLAYER_AGENCY_STATUS", function* () {
    try {
      const playerAgencyStatus = yield call(fetchPlayerAgencyStatus);
      if (!playerAgencyStatus.error && playerAgencyStatus.response) {
        yield put(
          actions.getPlayerAgencyStatusSuccess(playerAgencyStatus.response)
        );
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getScoutClubTeams() {
  yield takeEvery("GET_CLUB_TEAMS", function* () {
    try {
      const clubTeams = yield call(fetchClubTeams);
      if (!clubTeams.error && clubTeams.response) {
        yield put(actions.getClubTeamsSuccess(clubTeams.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getAgencyPlayersScout() {
  yield takeEvery(actions.GET_AGENCY_PLAYERS, function* ({ payload }) {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(fetchAgencyPlayers, payload.uid);
      if (!result.error && result.response) {
        yield put(actions.getAgencyPlayersSuccess(result.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getAllAgencyPlayersScout() {
  yield takeEvery(actions.GET_ALL_AGENCY_PLAYERS, function* ({ payload }) {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(
        fetchAllAgenciesPlayers,
        payload.fecini,
        payload.fecfin
      );
      if (!result.error && result.response) {
        yield put(actions.getAgencyPlayersSuccess(result.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getScoutAgents() {
  yield takeEvery(actions.GET_AGENTS, function* () {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(fetchAgents);
      if (!result.error && result.response) {
        yield put(actions.getAgentsSuccess(result.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getScoutAgencies() {
  yield takeEvery(actions.GET_AGENCIES, function* () {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(fetchAgencies);
      if (!result.error && result.response) {
        yield put(actions.getAgenciesSuccess(result.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* saveAgentScout() {
  yield takeEvery(actions.SAVE_AGENT, function* ({ payload }) {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(saveAgent, payload.agent);
      if (!result.error && result.response) {
        const agents = yield call(fetchAgents);
        yield put(actions.getAgentsSuccess(agents.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* savePlayerAgentScout() {
  yield takeEvery(actions.SAVE_AGENCY_PLAYER, function* ({ payload }) {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(savePlayerAgent, payload.player);
      if (!result.error && result.response) {
        const agencyPlayers = yield call(
          fetchAgencyPlayers,
          payload.player.uid_agency
        );
        yield put(actions.getAgencyPlayersSuccess(agencyPlayers.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* saveAgencyScout() {
  yield takeEvery(actions.SAVE_AGENCY, function* ({ payload }) {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(saveAgency, payload.agency);
      if (!result.error && result.response) {
        const agents = yield call(fetchAgents);

        yield put(actions.getAgentsSuccess(agents.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* saveShadowTeam() {
  yield takeEvery("SAVE_SHADOW_TEAM", function* ({ payload }) {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(updateShadowTeam, payload.currentShadowTeam);
      if (!result.error && result.response) {
        let currentShadowTeam = clone(payload.currentShadowTeam);
        if (result.response.uid) {
          currentShadowTeam.uid = result.response.uid;
        }
        const shadowTeams = yield call(getUserShadowTeams);

        yield put(
          actions.saveShadowTeamSuccess(currentShadowTeam, shadowTeams.response)
        );
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* fetchShadowTeamPlayers() {
  yield takeEvery(actions.GET_SHADOW_TEAM_PLAYERS, function* ({ payload }) {
    try {
      const result = yield call(getShadowTeamPlayers, payload.uid);
      if (!result.error && result.response) {
        yield put(actions.getShadowTeamPlayersSuccess(result.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* fetchShadowTeamStaff() {
  yield takeEvery(actions.GET_SHADOW_TEAM_STAFF, function* ({ payload }) {
    try {
      const result = yield call(getShadowTeamStaff, payload.uid);
      if (!result.error && result.response) {
        yield put(actions.getShadowTeamStaffSuccess(result.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* saveStaffShadowTeam() {
  yield takeEvery(actions.SAVE_STAFF_SHADOW_TEAM, function* ({ payload }) {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(updateRankingStaff, payload.staffShadowTeam);
      if (!result.error && result.response) {
        const shadowTeamStaff = yield call(
          getShadowTeamStaff,
          payload.staffShadowTeam.uid_ranking
        );

        yield put(actions.getShadowTeamStaffSuccess(shadowTeamStaff.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* savePlayerShadowTeam() {
  yield takeEvery("SAVE_PLAYER_SHADOW_TEAM", function* ({ payload }) {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(updatePlayerRanking, payload.playerShadowTeam);
      if (!result.error && result.response) {
        const shadowTeamPlayers = yield call(
          getShadowTeamPlayers,
          payload.playerShadowTeam.uid_ranking
        );

        yield put(
          actions.getShadowTeamPlayersSuccess(shadowTeamPlayers.response)
        );
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* filterPlayerReports() {
  yield takeEvery("FILTER_PLAYER_REPORTS", function* ({ payload }) {
    try {
      /*const result = yield call(apiHelper.scoutPlayerReport, payload.currentReport);*/
      const result = yield call(fetchPlayerReportsFiltered, {
        report_skills: payload.report_skills,
        player_detail: payload.player_detail,
      });
      if (!result.error && result.response) {
        yield put(actions.getScoutPlayersReportsSuccess(result.response));
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* getCountResumeReports() {
  yield takeEvery(actions.GET_PLAYER_REPORT_COUNT, function* ({ payload }) {
    try {
      const reportsCountUser = yield call(
        fetchReportsCountByUser,
        payload.date
      );
      const reportsCountCom = yield call(fetchReportsCountByCom, payload.date);
      const reportsCountLevel = yield call(
        fetchReportsCountByLevel,
        payload.date
      );
      const reportsCountPosition = yield call(
        fetchReportsCountByPosition,
        payload.date
      );
      if (
        reportsCountUser.response &&
        reportsCountPosition.response &&
        reportsCountLevel.response &&
        reportsCountCom
      ) {
        yield put(
          actions.getScoutReportsCountSuccess({
            user: reportsCountUser.response,
            com: reportsCountCom.response,
            level: reportsCountLevel.response,
            pos: reportsCountPosition.response,
          })
        );
      } else {
        yield put({ type: actions.API_SCOUT_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_SCOUT_ERROR });
    }
  });
}

export function* APIError() {
  yield takeEvery(actions.API_SCOUT_ERROR, function* () {
    clearToken();
    history.push("/signin");
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCurrentSeason),
    fork(saveShadowTeam),
    fork(getScoutSkills),
    fork(getScoutPlayersLevels),
    fork(getScoutPlayersPositions),
    fork(getScoutReports),
    fork(saveScoutReport),
    fork(getScoutPlayerReport),
    fork(getScoutPlayerReportsHistory),
    fork(filterPlayerReports),
    fork(getCountResumeReports),
    fork(getScoutRankings),
    fork(savePlayerShadowTeam),
    fork(fetchShadowTeamPlayers),
    fork(fetchShadowTeamStaff),
    fork(saveStaffShadowTeam),
    fork(saveAgentScout),
    fork(saveAgencyScout),
    fork(getScoutAgents),
    fork(getAgencyPlayersScout),
    fork(getScoutAgencies),
    fork(savePlayerAgentScout),
    fork(getAllAgencyPlayersScout),
    fork(getScoutClubTeams),
    fork(getScoutPlayerAgencyStatus),
    fork(deleteScoutAgency),
    fork(deleteScoutShadowPlayer),
    fork(deleteScoutShadowStaff),
    fork(getVideoSelectors),
    fork(getVideoPlayerBySelector),
    fork(deleteScoutReport),
    fork(APIError),
  ]);
}
