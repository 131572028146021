import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import Competitions from "@iso/redux/competitions/reducer";
import Wellness from "@iso/redux/wellness/reducer";
import Premium from "@iso/redux/premium/reducer";
import Scout from "@iso/redux/scout/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import profile from "@iso/redux/profile/reducer";

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Competitions,
  Wellness,
  Premium,
  Scout,
  profile,
});
