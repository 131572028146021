import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import { updateCurrentPassword } from "../../library/helpers/scoutApi";

import { getToken } from "@iso/lib/helpers/utility";
import authHelper from "@iso/lib/helpers/authHelper";
import actions from "./actions";

const history = createBrowserHistory();
const fakeApiCall = false; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
    const { token } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        profile: "Profile",
      });
    } else {
      if (fakeApiCall) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: "secret token",
          profile: "Profile",
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* jwtLoginRequest() {
  yield takeEvery(actions.JWT_LOGIN_REQUEST, function* ({ payload }) {
    const result = yield call(authHelper.login, payload.userInfo);
    if (result.error) {
      yield put({ type: actions.LOGIN_ERROR });
    } else {
      localStorage.setItem(
        "profile",
        JSON.stringify({ main_role: result.role, roles: result.all_roles })
      );
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: result.token,
        profile: { main_role: result.role, roles: result.all_roles },
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem("id_token", payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield localStorage.setItem("id_token", "");
    history.push("/");
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get("idToken");
    const profile = localStorage.getItem("profile");
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: JSON.parse(profile),
      });
    }
  });
}

export function* updatePass() {
  yield takeEvery(actions.CHANGE_PASS, function* ({ payload }) {
    const result = yield call(updateCurrentPassword, payload.data);
    if (result.error) {
      yield put({ type: actions.LOGIN_ERROR });
    } else {
      yield put({
        type: actions.CHANGE_PASS_SUCCESS,
        result: result,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(jwtLoginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(updatePass),
  ]);
}
