import { stubTrue } from "lodash-es";
import actions from "./actions";

const questions = [];
const answers = [];
const hurts = [];
const fullAnswers = [];
const fullHurts = [];
const sessions = [];
const players = [];
const teams = [];
const bodyPoints = [];
const newSession = "";
const answersRange = [];

const initState = {
  questions,
  answers,
  hurts,
  sessions,
  players,
  teams,
  bodyPoints,
  newSession,
  fullAnswers,
  fullHurts,
  answersRange,
};

export default function wellnessReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_BODYPOINTS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_BODYPOINTS_SUCCESS:
      return {
        ...state,
        loading: true,
        bodyPoints: action.payload.bodyPoints,
      };
    case actions.GET_SESSION_PLAYERS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SESSION_PLAYERS_SUCCESS:
      return {
        ...state,
        loading: true,
        players: action.payload.players,
      };
    case actions.GET_WELLNESS_QUESTIONS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_WELLNESS_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        questions: action.payload.questions,
      };
    case actions.SAVE_WELLNESS_ANSWERS:
      return {
        ...state,
        loading: true,
      };
    case actions.SAVE_WELLNESS_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        answers: action.answers,
      };
    case actions.GET_FULL_WELLNESS_HURTS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FULL_WELLNESS_HURTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        fullHurts: action.payload.hurts,
      };
    case actions.GET_WELLNESS_HURTS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_WELLNESS_HURTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        hurts: action.payload.hurts,
      };
    case actions.SAVE_WELLNESS_HURT:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FULL_WELLNESS_ANSWERS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FULL_WELLNESS_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        fullAnswers: action.payload.answers,
      };
    case actions.GET_WELLNESS_ANSWERS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_WELLNESS_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        answers: action.payload.answers,
      };
    case actions.GET_WELLNESS_ANSWERS_DATE_RANGE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actions.GET_WELLNESS_ANSWERS_DATE_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        answersRange: action.payload.answersRange,
      };
    case actions.GET_SESSIONS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        sessions: action.sessions,
      };
    case actions.SAVE_SESSION_PLAYER:
      return {
        ...state,
        loading: true,
      };
    case actions.SAVE_SESSION:
      return {
        ...state,
        loading: true,
      };
    case actions.SAVE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        newSession: action.payload.uid,
      };
    default:
      return state;
  }
}
