import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import {
  fetchPremiumCompetitions,
  fetchPremiumTypes,
  fetchPremiumGroupGames,
  fetchPremiumGroups,
  fetchPremiumStandings,
  fetchPremiumTeamPlayers,
  fetchPremiumReport,
  fetchPremiumPlayerGames,
  fetchPremiumPlayerGamesV2,
  addFavouriteCompetition,
  fetchFavouriteCompetitions,
  deleteFavouriteCompetition,
  addFavouritePlayer,
  fetchFavouritePlayers,
  addFavouriteTeam,
  fetchFavouriteTeams,
  fetchPremiumPlayerDetail,
  fetchPremiumCompetitionPlayers,
  fetchPremiumPlayerStats,
  fetchPremiumPlayerStatsPositionSeason,
  fetchCompetitionStatsByPosition,
  fetchOutstandingDashboard,
  fetchBestLineup,
  searchPlayer,
  globalSearch,
  fetchNextPlayerGames,
  fetchPlayerCareer,
} from "../../library/helpers/scoutApi";
import { createBrowserHistory } from "history";
import { clearToken } from "@iso/lib/helpers/utility";
import clone from "clone";
import { get } from "lodash-es";

const history = createBrowserHistory({ forceRefresh: true });

export function* getPremiumTypes() {
  yield takeEvery("GET_PREMIUM_TYPES", function* () {
    try {
      const premiumTypes = yield call(fetchPremiumTypes);

      if (!premiumTypes.error && premiumTypes.response) {
        yield put(actions.getPremiumTypesSuccess(premiumTypes.response));
      } else {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: { msg: "Premium Type Error" },
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_PREMIUM_ERROR,
        payload: { msg: "Premium Type Error Catch" },
      });
    }
  });
}

export function* getPremiumCompetitions() {
  yield takeEvery("GET_PREMIUM_COMPETITIONS", function* ({ payload }) {
    try {
      const competitions = yield call(
        fetchPremiumCompetitions,
        payload.selected_type
      );
      if (!competitions.error && competitions.response) {
        //FilterSeasons

        const seasons = Array.from(
          new Set(competitions.response.map((c) => c.uid_tem))
        ).map((uid) => {
          const com = competitions.response.find((c) => c.uid_tem === uid);
          return {
            uid_tem: uid,
            nom_tem: com.nom_tem,
            active: com.active,
          };
        });
        const countries = Array.from(
          new Set(competitions.response.map((c) => c.uid_country))
        ).map((uid) => {
          return {
            uid_country: uid,
            country_name: competitions.response.find(
              (c) => c.uid_country === uid
            ).country_name,
          };
        });
        const filteredcompetitions = Array.from(
          new Set(competitions.response.map((c) => c.uid_com))
        ).map((uid) => {
          return {
            uid_com: uid,
            nom_com: competitions.response.find((c) => c.uid_com === uid)
              .nom_com,
          };
        });
        yield put(
          actions.getPremiumCompetitionsSuccess(
            competitions.response,
            filteredcompetitions,
            countries,
            seasons
          )
        );
      } else {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: {
            msg: "Competitions response Error",
            response: competitions,
          },
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_PREMIUM_ERROR,
        payload: { msg: `Competitions Error V2 ${JSON.stringify(error)}` },
      });
    }
  });
}

export function* fetchCompetitionGroups() {
  yield takeEvery("GET_COMPETITION_GROUPS", function* ({ payload }) {
    try {
      let groups = yield call(
        fetchPremiumGroups,
        payload.selected_fed,
        payload.selected_com,
        payload.selected_season
      );
      if (!groups.error && groups.response) {
        yield put(actions.getPremiumGroupsSuccess(groups.response));
      } else {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: { msg: "Groups response Error" },
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_PREMIUM_ERROR,
        payload: { msg: "Groups response Error" },
      });
    }
  });
}

export function* getPremiumGroups() {
  yield takeEvery("SELECT_SEASON", function* ({ payload }) {
    try {
      let groups = yield call(
        fetchPremiumGroups,
        payload.fed,
        payload.selectedCompetition[0].uid_com,
        payload.selectedSeason[0].uid_tem
      );
      if (!groups.error && groups.response) {
        //FilterSeasons
        groups = groups.response.map((g) => {
          const isFav = payload.favouriteCompetitions.filter((c) => {
            return (
              c.uid_com === payload.selectedCompetition[0].uid_com &&
              c.uid_gru === g.uid_gru
            );
          });
          g.isFav = isFav.length > 0 ? 1 : 0;
          return g;
        });
        yield put(actions.getPremiumGroupsSuccess(groups));
      } else {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: { msg: "Groups response Error" },
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_PREMIUM_ERROR,
        payload: { msg: "Groups response Error" },
      });
    }
  });
}

export function* getPremiumCompetitionPlayers() {
  yield takeEvery(
    actions.GET_PREMIUM_COMPETITION_PLAYERS,
    function* ({ payload }) {
      try {
        const competitionPlayers = yield call(
          fetchPremiumCompetitionPlayers,
          payload.fed,
          payload.selectedCompetition[0].uid_com,
          payload.selectedSeason[0].uid_tem,
          payload.position
        );
        if (!competitionPlayers.error && competitionPlayers.response) {
          yield put(
            actions.getCompetitionPlayersSuccess(competitionPlayers.response)
          );
        } else {
          yield put({
            type: actions.API_PREMIUM_ERROR,
            payload: { msg: "Competitions players Error" },
          });
        }
      } catch (error) {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: { msg: "Competitions players Error" },
        });
      }
    }
  );
}

export function* getBestLineup() {
  yield takeEvery(actions.GET_PREMIUM_BEST_LINEUP, function* ({ payload }) {
    try {
      const competitionPlayers = yield call(
        fetchBestLineup,
        payload.uid_fed,
        payload.uid_com,
        payload.uid_gru,
        payload.uid_season,
        payload.minutes,
        payload.year,
        payload.level
      );
      if (!competitionPlayers.error && competitionPlayers.response) {
        yield put(actions.getBestLineupSuccess(competitionPlayers.response));
      } else {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: { msg: "Best Lineup response Error" },
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_PREMIUM_ERROR,
        payload: { msg: "Best Lineup response Error" },
      });
    }
  });
}

export function* searchForPlayers() {
  yield takeEvery(actions.SEARCH_PLAYER, function* ({ payload }) {
    try {
      const playerList = yield call(
        searchPlayer,
        payload.fed,
        payload.player_name
      );
      if (!playerList.error && playerList.response) {
        yield put(actions.searchPlayersSuccess(playerList.response));
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* fetchGlobalSearch() {
  yield takeEvery(actions.GLOBAL_SEARCH, function* ({ payload }) {
    try {
      const searchResult = yield call(
        globalSearch,
        payload.fed,
        payload.player_name,
        payload.team_name,
        payload.years
      );
      if (!searchResult.error && searchResult.response) {
        yield put(actions.globalSearchSuccess(searchResult.response));
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* getPlayerCareer() {
  yield takeEvery(actions.GET_PLAYER_CAREER, function* ({ payload }) {
    try {
      const playerCareer = yield call(
        fetchPlayerCareer,
        payload.uid_fed,
        payload.uid_pla
      );
      if (!playerCareer.error && playerCareer.response) {
        yield put(actions.getPlayerCareerSuccess(playerCareer.response));
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* getNextPlayerGames() {
  yield takeEvery(actions.GET_NEXT_PLAYER_GAMES, function* ({ payload }) {
    try {
      const games = yield call(
        fetchNextPlayerGames,
        payload.uid_fed,
        payload.uid_pla
      );
      if (!games.error && games.response) {
        yield put(actions.getNextPlayerGamesSuccess(games.response));
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* playerStatsPosition() {
  yield takeEvery(
    actions.GET_PREMIUM_PLAYER_STATS_POSITION,
    function* ({ payload }) {
      try {
        console.log(payload);
        const playerStats = yield call(
          fetchPremiumPlayerStatsPositionSeason,
          payload.fed,
          payload.player.uid_pla,
          payload.pos,
          payload.season === "_" ? payload.season : payload.season.uid_season,
          payload.fromDate,
          payload.toDate
        );
        let seasonData;
        if (payload.withCompetition)
          seasonData = yield call(
            fetchCompetitionStatsByPosition,
            payload.fed,
            payload.pos,
            payload.season.uid_season
          );
        if (!playerStats.error && playerStats.response) {
          //FilterSeasons
          yield put(
            actions.getPlayerStatsSuccess({
              player: payload.player,
              playerData: playerStats.response,
              seasonData: seasonData ? seasonData.response : [],
              season: payload.season,
              withCompetition: payload.withCompetition,
              fromDate: payload.fromDate,
            })
          );
        } else {
          yield put({ type: actions.API_PREMIUM_ERROR });
        }
      } catch (error) {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    }
  );
}

export function* playerStats() {
  yield takeEvery(actions.GET_PREMIUM_PLAYER_STATS, function* ({ payload }) {
    try {
      const playerStats = yield call(
        fetchPremiumPlayerStats,
        payload.fed,
        payload.selectedCompetition[0].uid_com,
        payload.player.uid_pla,
        payload.selectedSeason[0].uid_tem
      );
      if (!playerStats.error && playerStats.response) {
        //FilterSeasons

        yield put(
          actions.getPlayerStatsSuccess({
            player: payload.player,
            playerData: playerStats.response,
            season: "_",
          })
        );
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* getPremiumStandings() {
  yield takeEvery("GET_PREMIUM_STANDINGS", function* ({ payload }) {
    try {
      const standing = yield call(
        fetchPremiumStandings,
        payload.fed,
        payload.selectedCompetition[0].uid_com,
        payload.selectedGroup[0].uid_gru,
        payload.selectedSeason[0].uid_tem
      );
      if (!standing.error && standing.response) {
        //FilterSeasons
        yield put(actions.getGroupStandingsSuccess(standing.response));
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* getPremiumGroupGames() {
  yield takeEvery("GET_PREMIUM_GAMES", function* ({ payload }) {
    try {
      const games = yield call(
        fetchPremiumGroupGames,
        payload.fed,
        payload.selectedCompetition[0].uid_com,
        payload.selectedGroup[0].uid_gru,
        payload.selectedSeason[0].uid_tem
      );
      if (games.response) {
        //FilterSeasons
        yield put(actions.getGroupGamesSuccess(games.response));
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* setPremiumCompetitionFavourite() {
  yield takeEvery("SET_FAVOURITE_COMPETITION", function* ({ payload }) {
    try {
      const favres = yield call(
        addFavouriteCompetition,
        payload.favouriteCompetition
      );
      if (favres.response) {
        //FilterSeasons
        yield put(actions.getFavouriteCompetitions());
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* removeFavouritecometition() {
  yield takeEvery("REMOVE_FAVOURITE_COMPETITION", function* ({ payload }) {
    try {
      const favres = yield call(deleteFavouriteCompetition, payload.uid_gru);
      if (favres.response) {
        //FilterSeasons
        yield put(actions.getFavouriteCompetitions());
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* getFavouriteCompetitions() {
  yield takeEvery("GET_FAVOURITE_COMPETITIONS", function* () {
    try {
      const favouriteCompetitions = yield call(fetchFavouriteCompetitions);

      if (!favouriteCompetitions.error && favouriteCompetitions.response) {
        yield put(
          actions.getFavouriteCompetitionsSuccess(
            favouriteCompetitions.response
          )
        );
      } else {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: { msg: "Favourite Competitions response Error" },
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_PREMIUM_ERROR,
        payload: { msg: "Favourite competitions Error" },
      });
    }
  });
}

export function* setPremiumFavouritePlayer() {
  yield takeEvery(actions.SET_FAVOURITE_PLAYER, function* ({ payload }) {
    try {
      const favres = yield call(addFavouritePlayer, payload.favouritePlayer);
      if (favres.response) {
        //FilterSeasons
        yield put(actions.setPremiumFavouritePlayerSuccess(favres.response));
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}
export function* getFavouritePlayers() {
  yield takeEvery(actions.GET_FAVOURITE_PLAYERS, function* () {
    try {
      const favouritePlayers = yield call(fetchFavouritePlayers);
      if (!favouritePlayers.error && favouritePlayers.response) {
        yield put(
          actions.getFavouritePlayersSuccess(favouritePlayers.response)
        );
      } else {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: { msg: "Favourite Players response Error" },
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_PREMIUM_ERROR,
        payload: { msg: "FAvourite Players response Error CAtch" },
      });
    }
  });
}

export function* setPremiumFavouriteTeam() {
  yield takeEvery(actions.SET_FAVOURITE_TEAM, function* ({ payload }) {
    try {
      const favres = yield call(addFavouriteTeam, payload.favouriteTeam);
      if (favres.response) {
        //FilterSeasons
        yield put(actions.setPremiumFavouriteTeamSuccess(favres.response));
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}
export function* getFavouriteTeams() {
  yield takeEvery(actions.GET_FAVOURITE_TEAMS, function* () {
    try {
      const favouriteTeams = yield call(fetchFavouriteTeams);

      if (!favouriteTeams.error && favouriteTeams.response) {
        yield put(actions.getFavouriteTeamSuccess(favouriteTeams.response));
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* getPremiumGameReport() {
  yield takeEvery("GET_PREMIUM_GAME_REPORT", function* ({ payload }) {
    try {
      const report = yield call(
        fetchPremiumReport,
        payload.fed,
        payload.selectedCompetition[0].uid_com,
        payload.selectedGame.uid_game
      );
      if (!report.error && report.response) {
        //FilterSeasons
        let game = clone(payload.game);
        const selectedGame = payload.selectedGame;
        game.home.color.bg1 = game.bgh1g ? game.bgh1g : selectedGame.bg1h;
        game.home.color.bg2 = game.bgh2g ? game.bgh2g : selectedGame.bg2h;
        game.home.color.text = selectedGame.tch === 0 ? "#000000" : "#ffffff";
        game.home.tactic = selectedGame.tactic_h
          ? selectedGame.tactic_h
          : "433";
        game.home.players = report.response.filter((g) => {
          return g.uid_team === selectedGame.uid_loc;
        });
        game.away.color.bg1 = game.bga1g ? game.bga1g : selectedGame.bg1a;
        game.away.color.bg2 = game.bga2g ? game.bga2g : selectedGame.bg2a;
        game.away.color.text = selectedGame.tca === 0 ? "#000000" : "#ffffff";
        game.away.tactic = selectedGame.tactic_a
          ? selectedGame.tactic_a
          : "433";
        game.away.players = report.response.filter((g) => {
          return g.uid_team === selectedGame.uid_vis;
        });
        yield put(actions.getGameReportSuccess(game));
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* getPremiumTeamPlayers() {
  yield takeEvery("GET_PREMIUM_TEAM_PLAYERS", function* ({ payload }) {
    try {
      const players = yield call(
        fetchPremiumTeamPlayers,
        payload.fed,
        payload.selectedCompetition.uid_com,
        payload.selectedGroup.uid_gru,
        payload.team.uid_team,
        payload.selectedSeason.uid_tem
      );
      if (players.response) {
        //FilterSeasons
        yield put(actions.getTeamPlayersSuccess(players.response));
      } else {
        yield put({ type: actions.API_PREMIUM_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_PREMIUM_ERROR });
    }
  });
}

export function* getPremiumPlayerGames() {
  yield takeEvery("GET_PREMIUM_PLAYER_GAMES", function* ({ payload }) {
    try {
      const playerGames = yield call(
        fetchPremiumPlayerGames,
        payload.fed,
        payload.selectedCompetition[0].uid_com,
        payload.selectedPlayer.uid_pla,
        payload.selectedSeason[0].uid_tem
      );
      if (!playerGames.error && playerGames.response) {
        //FilterSeasons
        yield put(actions.getPremiumPlayerGamesSuccess(playerGames.response));
      } else {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: { msg: "Player Games error v1" },
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_PREMIUM_ERROR,
        payload: { msg: "Player Games error v1 2" },
      });
    }
  });
}

export function* requestPlayerGamesV2() {
  yield takeEvery("GET_PREMIUM_PLAYER_GAMESV2", function* ({ payload }) {
    try {
      const playerGames = yield call(
        fetchPremiumPlayerGamesV2,
        payload.uid_fed,
        payload.uid_pla
      );
      if (!playerGames.error && playerGames.response) {
        //FilterSeasons
        yield put(actions.getPremiumPlayerGamesSuccess(playerGames.response));
      } else {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: { msg: "Error Get Premium Player Gamesv2 " },
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_PREMIUM_ERROR,
        payload: { msg: "Error Get Premium Player Gamesv2 2" },
      });
    }
  });
}

export function* getPremiumPlayerDetail() {
  yield takeEvery("GET_PREMIUM_PLAYER_DETAIL", function* ({ payload }) {
    try {
      const playerDetail = yield call(
        fetchPremiumPlayerDetail,
        payload.uid_fed,
        payload.uid_pla,
        payload.uid_season
      );
      if (!playerDetail.error && playerDetail.response) {
        //FilterSeasons
        yield put(actions.getPremiumPlayerDetailSuccess(playerDetail.response));
      } else {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: { msg: "Outstandings Players Error" },
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_PREMIUM_ERROR,
        payload: { msg: "Outstandings Players Error" },
      });
    }
  });
}

export function* getOutstandingDashboard() {
  yield takeEvery("GET_PREMIUM_OUTSTANDINGDASHBOARD", function* ({ payload }) {
    try {
      let outstandingDashboard = yield call(
        fetchOutstandingDashboard,
        payload.filter
      );
      if (!outstandingDashboard.error && outstandingDashboard.response) {
        //FilterSeasons

        yield put(
          actions.getOutstandingDashboardsSuccess(outstandingDashboard.response)
        );
      } else {
        yield put({
          type: actions.API_PREMIUM_ERROR,
          payload: { msg: "Outstandings Players Error" },
        });
      }
    } catch (error) {
      yield put({
        type: actions.API_PREMIUM_ERROR,
        payload: { msg: "Outstandings Players Error 2" },
      });
    }
  });
}

export function* APIError() {
  yield takeEvery(actions.API_PREMIUM_ERROR, function* ({ payload }) {
    clearToken();
    history.push("/signin");
  });
}

export default function* rootSaga() {
  yield all([
    fork(getPremiumTypes),
    fork(getBestLineup),
    fork(getPremiumCompetitions),
    fork(fetchCompetitionGroups),
    fork(getPremiumGroups),
    fork(getPremiumStandings),
    fork(getPremiumGroupGames),
    fork(getPremiumGameReport),
    fork(getPremiumPlayerGames),
    fork(setPremiumCompetitionFavourite),
    fork(getFavouriteCompetitions),
    fork(removeFavouritecometition),
    fork(setPremiumFavouritePlayer),
    fork(getFavouritePlayers),
    fork(setPremiumFavouriteTeam),
    fork(getFavouriteTeams),
    fork(getPremiumTeamPlayers),
    fork(getPremiumPlayerDetail),
    fork(getPremiumCompetitionPlayers),
    fork(playerStats),
    fork(searchForPlayers),
    fork(playerStatsPosition),
    fork(getOutstandingDashboard),
    fork(fetchGlobalSearch),
    fork(getNextPlayerGames),
    fork(getPlayerCareer),
    fork(requestPlayerGamesV2),
    fork(APIError),
  ]);
}
