import actions from "./actions";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import { clearToken } from "@iso/lib/helpers/utility";
import moment from "moment";
import {
  fetchSessions,
  fetchSessionPlayers,
  fetchQuestions,
  fetchBodyPoints,
  fetchAnswers,
  fetchHurts,
  saveAnswer,
  saveSession,
  deleteSession,
  saveSessionPlayer,
  saveHurt,
  fetchFullAnswers,
  fetchFullHurts,
  fetchWellnessAnswersByDateRange,
} from "../../library/helpers/scoutApi";

const history = createBrowserHistory({ forceRefresh: true });

export function* apiGetBodyPoints() {
  yield takeEvery("GET_BODYPOINTS", function* () {
    try {
      const points = yield call(fetchBodyPoints);
      if (!points.error && points.response) {
        yield put(actions.getBodyPointsSuccess(points.response));
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* apiGetQuestions() {
  yield takeEvery(actions.GET_WELLNESS_QUESTIONS, function* () {
    try {
      const questions = yield call(fetchQuestions);
      if (!questions.error && questions.response) {
        yield put(actions.getQuestionsSuccess(questions.response));
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* getSessions() {
  yield takeEvery("GET_SESSIONS", function* ({ payload }) {
    try {
      const sessions = yield call(fetchSessions, payload.date);
      if (!sessions.error && sessions.response) {
        yield put(actions.getSessionsSuccess(sessions.response));
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* updateSession() {
  yield takeEvery(actions.SAVE_SESSION, function* ({ payload }) {
    try {
      const sessions = yield call(saveSession, payload.session);
      if (!sessions.error && sessions.response) {
        if (sessions.response.new_uid) {
          const players = yield call(
            fetchSessionPlayers,
            sessions.response.new_uid
          );
          if (players.response && !players.error) {
            yield put(actions.getSessionPlayersSuccess(players.response));
          }
          yield put(actions.saveSessionSuccess(sessions.response.new_uid));
        }
        yield put(
          actions.getSessions(moment(payload.session.fec).format("YYYY-MM-DD"))
        );
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* apiGetSessionPlayers() {
  yield takeEvery(actions.GET_SESSION_PLAYERS, function* ({ payload }) {
    try {
      const players = yield call(fetchSessionPlayers, payload.uid);
      if (!players.error && players.response) {
        yield put(actions.getSessionPlayersSuccess(players.response));
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* savePlayerSession() {
  yield takeEvery(actions.SAVE_SESSION_PLAYER, function* ({ payload }) {
    try {
      const playerSave = yield call(saveSessionPlayer, payload.player);
      if (!playerSave.error && playerSave.response) {
        const players = yield call(
          fetchSessionPlayers,
          payload.player.uid_session
        );
        if (players.response && !players.error) {
          yield put(actions.getSessionPlayersSuccess(players.response));
        }
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* removeSession() {
  yield takeEvery(actions.DELETE_SESSION, function* ({ payload }) {
    try {
      const sessions = yield call(deleteSession, payload.session.uid);
      if (!sessions.error && sessions.response) {
        yield put(actions.getSessions(payload.session.fec));
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* apiGetSessionAnswers() {
  yield takeEvery(actions.GET_WELLNESS_ANSWERS, function* ({ payload }) {
    try {
      const answers = yield call(fetchAnswers, payload.uid);
      if (!answers.error && answers.response) {
        yield put(actions.getAnswersSuccess(answers.response));
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}
export function* apiGetFullSessionAnswers() {
  yield takeEvery(actions.GET_FULL_WELLNESS_ANSWERS, function* ({ payload }) {
    try {
      const answers = yield call(fetchFullAnswers, payload.uid);
      if (!answers.error && answers.response) {
        yield put(actions.getFullAnswersSuccess(answers.response));
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* apiGetAnswersByDateRange() {
  yield takeEvery(
    actions.GET_WELLNESS_ANSWERS_DATE_RANGE,
    function* ({ payload }) {
      try {
        const answers = yield call(
          fetchWellnessAnswersByDateRange,
          payload.dates
        );
        if (!answers.error && answers.response) {
          yield put(actions.getAnswersByDateRangeSuccess(answers.response));
        } else {
          yield put({ type: actions.API_WELLNESS_ERROR });
        }
      } catch (error) {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    }
  );
}

export function* savePlayerAnswer() {
  yield takeEvery(actions.SAVE_WELLNESS_ANSWERS, function* ({ payload }) {
    try {
      const answerSave = yield call(saveAnswer, payload.answer);
      if (!answerSave.error && answerSave.response) {
        const answers = yield call(fetchAnswers, payload.answer.uid_session);
        if (answers.response && !answers.error) {
          yield put(actions.getAnswersSuccess(answers.response));
        }
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* apiGetSessionHurts() {
  yield takeEvery(actions.GET_WELLNESS_HURTS, function* ({ payload }) {
    try {
      const hurts = yield call(fetchHurts, payload.uid);
      if (!hurts.error && hurts.response) {
        yield put(actions.getHurtsSuccess(hurts.response));
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* apiGetFullSessionHurts() {
  yield takeEvery(actions.GET_FULL_WELLNESS_HURTS, function* ({ payload }) {
    try {
      const hurts = yield call(fetchFullHurts, payload.uid);
      if (!hurts.error && hurts.response) {
        yield put(actions.getFullHurtsSuccess(hurts.response));
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* savePlayerHurt() {
  yield takeEvery(actions.SAVE_WELLNESS_HURT, function* ({ payload }) {
    try {
      const hurtSave = yield call(saveHurt, payload.hurt);
      if (!hurtSave.error && hurtSave.response) {
        const hurts = yield call(fetchHurts, payload.hurt.uid_session);
        if (hurts.response && !hurts.error) {
          yield put(actions.getHurtsSuccess(hurts.response));
        }
      } else {
        yield put({ type: actions.API_WELLNESS_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_WELLNESS_ERROR });
    }
  });
}

export function* WellnessAPIError() {
  // eslint-disable-next-line require-yield
  yield takeEvery(actions.API_WELLNESS_ERROR, function* () {
    clearToken();
    history.push("/signin");
  });
}

export default function* rootSaga() {
  yield all([
    fork(apiGetBodyPoints),
    fork(apiGetQuestions),
    fork(WellnessAPIError),
    fork(getSessions),
    fork(updateSession),
    fork(apiGetSessionPlayers),
    fork(apiGetSessionHurts),
    fork(apiGetSessionAnswers),
    fork(savePlayerSession),
    fork(savePlayerAnswer),
    fork(savePlayerHurt),
    fork(apiGetFullSessionHurts),
    fork(apiGetFullSessionAnswers),
    fork(apiGetAnswersByDateRange),
    fork(removeSession),
  ]);
}
