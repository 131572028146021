import { Map } from "immutable";

export function clearToken() {
  localStorage.removeItem("id_token");
}

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return `${color}80`;
}
export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function getStatColor(i) {
  const colors = [
    "#00ffff",
    "#ffde41",
    "#ff1e0f",
    "#1eff43",
    "#e94aff",
    "#3b2cff",
    "#ff8e06",
    "#ffd0eb",
    "#dddfff",
    "#7707ff",
    "#ddd3ff",
  ];
  return `${colors[i]}80`;
}

export function random_rgba() {
  var o = Math.round,
    r = Math.random,
    s = 255;
  return "rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + ", 0.5)";
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("id_token");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function mapSpecificPosition(pos) {
  switch (pos) {
    case "GK":
      return 1;
    case "RB":
    case "RB5":
    case "RWB":
      return 2;
    case "LB":
    case "LB5":
    case "LWB":
      return 3;
    case "CB":
    case "RC":
    case "RCB":
    case "RCB3":
      return 4;
    case "LCB":
    case "LCB3":
      return 5;
    case "DMF":
    case "LCMF3":
    case "LDMF":
    case "RDMF":
      return 6;
    case "RW":
    case "RWF":
      return 7;
    case "LCMF":
    case "RCMF":
    case "RCMF3":
      return 8;
    case "CF":
    case "SS":
    case "FW":
      return 9;
    case "AMF":
    case "LAMF":
    case "RAMF":
      return 10;
    case "LW":
    case "LWF":
      return 11;
    default:
      return 9;
  }
}

export function mapPlayerPosition(num) {
  switch (num) {
    case 1:
      return "Portero";
    case 2:
      return "Lateral";
    case 3:
      return "Lateral";
    case 4:
      return "Central";
    case 5:
      return "Central";
    case 6:
      return "Pivote";
    case 7:
      return "Extremo";
    case 8:
      return "Mediocentro";
    case 9:
      return "Delantero";
    case 10:
      return "Media Punta";
    case 11:
      return "Extremo";
    default:
      return "Sin Definir";
  }
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export function delay(delayTime = 1000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, delayTime);
  });
}
