import { all } from "redux-saga/effects";
import authSagas from "@iso/redux/auth/saga";
import competitionSagas from "@iso/redux/competitions/saga";
import wellnessSagas from "./wellness/saga";
import premiumSagas from "@iso/redux/premium/saga";
import scout from "@iso/redux/scout/saga";
import profileSaga from "@iso/redux/profile/saga";

export default function* rootSaga(getState) {
  yield all([
    scout(),
    authSagas(),
    competitionSagas(),
    premiumSagas(),
    profileSaga(),
    wellnessSagas(),
  ]);
}
