import actions from "./actions";

const skills = [];
const agents = [];
const videos = [];
const videoSelectors = [];
const agencies = [];
const agencyPlayers = [];
const positions = [];
const shadowTeams = [];
const playerAgencyStatus = [];
const shadowTeamPlayers = [];
const loadingScout = false;
const errorScout = false;
const newReportSaved = false;
const playerPosition = 0;
const currentSeason = { uid: "" };
const playerSkills = [];
const playerReportHistory = [];
const clubTeams = [];
const shadowTeamStaff = [];
const videoLoading = false;
const currentReport = null;
const foots = [
  { id: 0, name: "No value" },
  { id: 1, name: "Right" },
  { id: 2, name: "Left" },
  { id: 3, name: "Both" },
];
const reports = [];
const playerLevels = [];
const reportsCount = { user: [], com: [], level: [], pos: [] };

const currentShadowTeam = {
  nom: "-",
  season: "-",
  year_ini: 1983,
  year_end: 2020,
};

const initState = {
  skills,
  positions,
  loadingScout,
  errorScout,
  playerPosition,
  playerSkills,
  reports,
  currentReport,
  foots,
  playerLevels,
  playerReportHistory,
  reportsCount,
  currentShadowTeam,
  shadowTeams,
  shadowTeamPlayers,
  currentSeason,
  shadowTeamStaff,
  agents,
  agencyPlayers,
  agencies,
  clubTeams,
  playerAgencyStatus,
  newReportSaved,
  videos,
  videoSelectors,
  videoLoading,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_VIDEOS:
      return {
        ...state,
        videos: [],
      };
    case actions.GET_VIDEO_SELECTORS:
      return {
        ...state,
        loadingScout: true,
        videoLoading: true,
      };
    case actions.GET_VIDEO_SELECTORS_SUCCESS:
      return {
        ...state,
        videoSelectors: action.selectors,
      };
    case actions.GET_VIDEO_MATCH:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_VIDEO_PLAYER_MATCH:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_VIDEO_PLAYER_SELECTOR:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: action.videos,
        videoLoading: false,
      };
    case actions.DELETE_AGENCY:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_AGENTS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_AGENTS_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        agents: action.agents,
      };
    case actions.GET_PLAYER_AGENCY_STATUS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_PLAYER_AGENCY_STATUS_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        playerAgencyStatus: action.playerAgencyStatus,
      };
    case actions.GET_CLUB_TEAMS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_CLUB_TEAMS_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        clubTeams: action.clubTeams,
      };
    case actions.GET_AGENCIES:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_AGENCIES_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        agencies: action.agencies,
      };
    case actions.GET_AGENCY_PLAYERS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_AGENCY_PLAYERS_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        agencyPlayers: action.agencyPlayers,
      };
    case actions.GET_ALL_AGENCY_PLAYERS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.SAVE_AGENCY_PLAYER:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.SAVE_AGENCY:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_CURRENT_SEASON:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_CURRENT_SEASON_SUCCESS:
      return {
        ...state,
        loadingScout: true,
        currentSeason: action.currentSeason,
      };
    case actions.GET_USER_SHADOW_TEAMS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_USER_SHADOW_TEAMS_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        errorScout: false,
        shadowTeams: action.shadowTeams,
      };
    case actions.GET_SHADOW_TEAM_PLAYERS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_SHADOW_TEAM_PLAYERS_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        errorScout: false,
        shadowTeamPlayers: action.shadowTeamPlayers,
      };
    case actions.GET_SHADOW_TEAM_STAFF_SUCCESS:
      return {
        ...state,
        shadowTeamStaff: action.shadowTeamStaff,
      };
    case actions.SAVE_SHADOW_TEAM:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.SAVE_SHADOW_TEAM_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        currentShadowTeam: action.payload.currentShadowTeam,
        shadowTeams: action.payload.shadowTeams,
      };
    case actions.SAVE_PLAYER_SHADOW_TEAM:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.SAVE_PLAYER_SHADOW_TEAM_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        shadowTeamPlayers: action.payload.shadowTeamPlayers,
      };
    case actions.SAVE_STAFF_SHADOW_TEAM:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.SAVE_STAFF_SHADOW_TEAM_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        shadowTeamStaff: action.payload.shadowTeamStaff,
      };
    case actions.FILTER_PLAYER_REPORTS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_SCOUT_PLAYER_LEVELS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_SCOUT_PLAYER_LEVELS_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        errorScout: false,
        playerLevels: action.playerLevels,
      };
    case actions.GET_SCOUT_SKILLS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_SCOUT_SKILLS_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        errorScout: false,
        skills: action.skills,
      };
    case actions.GET_SCOUT_PLAYER_POSITIONS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_SCOUT_PLAYER_POSITIONS_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        errorScout: false,
        positions: action.positions,
      };
    case actions.GET_SCOUT_PLAYER_REPORTS:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_SCOUT_PLAYER_REPORTS_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        errorScout: false,
        reports: action.reports,
      };
    case actions.GET_PLAYER_REPORT_HISTORY:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_PLAYER_REPORT_HISTORY_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        errorScout: false,
        playerReportHistory: action.payload.playerReportHistory,
      };
    case actions.GET_SCOUT_SELECTED_PLAYER_REPORT:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_SCOUT_SELECTED_PLAYER_REPORT_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        errorScout: false,
        playerPosition: action.payload.selectedPosition,
        currentReport: action.payload.currentReport,
        playerSkills: action.payload.playerSkills,
      };
    case actions.GET_PLAYER_REPORT_COUNT:
      return {
        ...state,
        loadingScout: true,
      };
    case actions.GET_PLAYER_REPORT_COUNT_SUCCESS:
      return {
        ...state,
        loadingScout: false,
        errorScout: false,
        reportsCount: action.payload.reportsCount,
      };
    case actions.INIT_PLAYER_REPORT:
      return {
        ...state,
        currentReport: action.payload.currentReport,
        playerSkills: action.payload.skills,
      };
    case actions.SELECT_PLAYER_REPORT:
      return {
        ...state,
        report: action.payload.report,
      };
    case actions.SELECT_PLAYER_POSITION:
      return {
        ...state,
        playerPosition: action.payload.selectedPosition,
        playerSkills: action.payload.playerSkills,
        currentReport: action.payload.currentReport,
      };
    case actions.CHANGE_PLAYER_FOOT:
      return {
        ...state,
        currentReport: action.payload.currentReport,
      };
    case actions.CHANGE_PLAYER_REPORT:
      return {
        ...state,
        currentReport: action.payload.currentReport,
      };
    case actions.CHANGE_PLAYER_SKILL:
      return {
        ...state,
        playerSkills: action.payload.playerSkills,
      };
    case actions.SAVE_PLAYER_REPORT:
      return {
        ...state,
        loadingScout: true,
        newReportSaved: false,
      };
    case actions.SAVE_PLAYER_REPORT_SUCCESS:
      return {
        ...state,
        currentReport: action.report,
        loadingScout: false,
        newReportSaved: true,
      };
    case actions.API_SCOUT_ERROR:
      return initState;
    default:
      return state;
  }
}
