import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import {
  fetchFederations,
  fetchCompetitions,
  fetchSeasons,
  fetchGroups,
  fetchStandings,
  fetchSquad,
  fetchGroupGames,
  fetchOutstandingPlayersGroup,
  searchFedPlayer,
  teamSearch,
} from "../../library/helpers/scoutApi";

export function* getFederations() {
  yield takeEvery("GET_FEDERATIONS", function* () {
    try {
      const federations = yield call(fetchFederations);

      if (federations.response) {
        yield put(actions.getFederationsSuccess(federations.response));
      } else {
        yield put({ type: actions.API_ERROR });
      }
    } catch (error) {
      yield put({ type: actions.API_ERROR });
    }
  });
}

function* getSeasons({ payload }) {
  //const { fed } = payload;
  //
  try {
    const seasons = yield call(fetchSeasons, payload.selected_fed);

    if (seasons.response) {
      yield put(actions.getSeasonsSuccess(seasons.response));
    } else {
      yield put({ type: actions.API_ERROR });
    }
  } catch (error) {
    yield put({ type: actions.API_ERROR });
  }
}
function* getCompetitions({ payload }) {
  try {
    //

    //const payload={data}
    const competitions = yield call(
      fetchCompetitions,
      payload.selected_fed,
      payload.selected_tem
    );

    if (competitions.response) {
      yield put(actions.getCompetitionsSuccess(competitions.response));
    } else {
      yield put({ type: actions.API_ERROR });
    }
  } catch (error) {
    yield put({ type: actions.API_ERROR });
  }
}

function* getGroups({ payload }) {
  try {
    const groups = yield call(
      fetchGroups,
      payload.selected_fed,
      payload.selected_com
    );

    if (groups.response) {
      yield put(actions.getGroupsSuccess(groups.response));
    } else {
      yield put({ type: actions.API_ERROR });
    }
  } catch (error) {
    yield put({ type: actions.API_ERROR });
  }
}

function* getStandings({ payload }) {
  try {
    const groups = yield call(
      fetchStandings,
      payload.selected_fed,
      payload.selected_com,
      payload.selected_gru
    );

    if (groups.response) {
      yield put(actions.getStandingSuccess(groups.response));
    } else {
      yield put({ type: actions.API_ERROR });
    }
  } catch (error) {
    yield put({ type: actions.API_ERROR });
  }
}

function* getGroupGames({ payload }) {
  try {
    const games = yield call(
      fetchGroupGames,
      payload.selected_fed,
      payload.selected_com,
      payload.selected_gru
    );

    if (games.response) {
      yield put(actions.getGroupGamesSuccess(games.response));
    } else {
      yield put({ type: actions.API_ERROR });
    }
  } catch (error) {
    yield put({ type: actions.API_ERROR });
  }
}

function* getOutstandingGroup({ payload }) {
  try {
    const outstanding = yield call(
      fetchOutstandingPlayersGroup,
      payload.selected_fed,
      payload.selected_com,
      payload.selected_gru,
      payload.pos
    );

    if (outstanding.response) {
      yield put(actions.getOutstandingGroupSuccess(outstanding.response));
    } else {
      yield put({ type: actions.API_ERROR });
    }
  } catch (error) {
    yield put({ type: actions.API_ERROR });
  }
}

function* competitionSearchPlayers({ payload }) {
  try {
    const players = yield call(
      searchFedPlayer,
      payload.selected_fed,
      payload.player_name,
      payload.team_name
    );

    if (players.response) {
      yield put(actions.searchFedPlayersSuccess(players.response));
    } else {
      yield put({ type: actions.API_ERROR });
    }
  } catch (error) {
    yield put({ type: actions.API_ERROR });
  }
}

function* competitionSearchTeams({ payload }) {
  try {
    const teams = yield call(
      teamSearch,
      payload.selected_fed,
      payload.selected_season,
      payload.team_name
    );

    if (teams.response) {
      yield put(actions.searchTeamsSuccess(teams.response));
    } else {
      yield put({ type: actions.API_ERROR });
    }
  } catch (error) {
    yield put({ type: actions.API_ERROR });
  }
}

function* getSquad({ payload }) {
  try {
    const squad = yield call(
      fetchSquad,
      payload.selected_fed,
      payload.selected_com,
      payload.selected_tem,
      payload.selected_team
    );

    if (squad.response) {
      yield put(actions.getSquadSuccess(squad.response));
    } else {
      yield put({ type: actions.API_ERROR });
    }
  } catch (error) {
    yield put({ type: actions.API_ERROR });
  }
}

export function* APIError() {
  yield takeEvery(actions.API_ERROR, function* () {});
}

export default function* rootSaga() {
  yield all([
    fork(getFederations),
    takeEvery(actions.SEARCH_TEAMS, competitionSearchTeams),
    takeEvery(actions.SEARCH_PLAYERS_V2, competitionSearchPlayers),
    takeEvery(actions.GET_SEASONS, getSeasons),
    takeEvery(actions.GET_COMPETITIONS, getCompetitions),
    takeEvery(actions.GET_GROUPS, getGroups),
    takeEvery(actions.GET_STANDINGS, getStandings),
    takeEvery(actions.GET_GROUP_GAMES, getGroupGames),
    takeEvery(actions.GET_OUTSTANDING_GROUP, getOutstandingGroup),
    takeEvery(actions.GET_SQUAD, getSquad),
    fork(APIError),
  ]);
}
