import actions from "./actions";

const com = [];
const fed = [];
const seasons = [];
const gru = [];
const standing = [];
const squad = [];
const games = [];
const teamsSearch = [];
const outstanding = [];
const selectedSquad = "";
const selectedFederation = "";
const selectedSeason = "";
const selectedCompetition = "";
const selectedPlayer = "";
const selectedGroup = "";
const selectedGame = "";
const competition = { name: "", group: "" };
const searchPlayers = [];

const initState = {
  fed,
  com,
  seasons,
  gru,
  standing,
  squad,
  selectedSquad,
  selectedPlayer,
  selectedCompetition,
  games,
  outstanding,
  selectedSeason,
  selectedFederation,
  selectedGroup,
  selectedGame,
  competition,
  searchPlayers,
  teamsSearch,
};

export default function authReducer(state = initState, action) {
  const selectedFederation = state.selectedFederation;
  switch (action.type) {
    case actions.SEARCH_PLAYERS_V2:
      return {
        ...state,
        loading: true,
        selectedFederation: action.payload.selected_fed,
      };
    case actions.SEARCH_PLAYERS_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        searchPlayers: action.payload.players,
      };
    case actions.SEARCH_TEAMS:
      return {
        ...state,
        loading: true,
        selectedFederation: action.payload.selected_fed,
        selectedSeason: action.payload.selected_season,
      };
    case actions.SEARCH_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        teamsSearch: action.payload.teams,
      };
    case actions.GET_FEDERATIONS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SEASONS:
      return {
        ...state,
        loading: true,
        selectedFederation: action.payload.selected_fed,
      };
    case actions.GET_COMPETITIONS:
      return {
        ...state,
        loading: true,
        selectedSeason: action.payload.selected_tem,
      };
    case actions.GET_GROUPS:
      return {
        ...state,
        loading: true,
        selectedCompetition: action.payload.selected_com,
      };
    case actions.GET_STANDINGS:
      return {
        ...state,
        loading: true,
        selectedGroup: action.payload.selected_gru,
        competition: action.payload.com,
      };
    case actions.GET_GROUP_GAMES:
      return {
        ...state,
        loading: true,
        selectedGroup: action.payload.selected_gru,
      };
    case actions.GET_OUTSTANDING_GROUP:
      return {
        ...state,
        loading: true,
        selectedGroup: action.payload.selected_gru,
      };
    case actions.GET_SQUAD:
      return {
        ...state,
        loading: true,
        selectedSquad: action.payload.selected_team,
      };
    case actions.SELECT_PLAYER:
      return {
        ...state,
        selectedPlayer: action.id,
      };
    case actions.SELECT_SQUAD:
      return {
        ...state,
        selectedSquad: action.id,
        selectedFederation,
      };
    case actions.API_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        com: [],
        fed: [],
      };
    case actions.FEDERATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        fed: action.fed,
      };
    case actions.COMPETITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        com: action.com,
      };
    case actions.GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        gru: action.gru,
      };
    case actions.SEASONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        seasons: action.seasons,
      };
    case actions.STANDING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        standing: action.standing,
      };
    case actions.GROUP_GAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        games: action.games,
      };
    case actions.OUTSTANDING_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        outstanding: action.outstanding,
      };
    case actions.SQUAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        squad: action.squad,
      };
    default:
      return state;
  }
}
