import actions from "./actions";

const profile = localStorage.getItem("profile");

const initState = {
  idToken: null,
  profile: profile ? JSON.parse(profile) : null,
  loadingAuth: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        profile:
          action.profile && action.profile !== null ? action.profile : profile,
      };
    case actions.CHANGE_PASS:
      return {
        loadingAuth: true,
      };
    case actions.CHANGE_PASS_SUCCESS:
      return {
        loadingAuth: false,
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
