import clone from "clone";

const actions = {
  DELETE_AGENCY: "DELETE_AGENCY",
  DELETE_SHADOW_PLAYER: "DELETE_SHADOW_PLAYER",
  GET_CURRENT_SEASON: "GET_CURRENT_SEASON",
  GET_CURRENT_SEASON_SUCCESS: "GET_CURRENT_SEASON_SUCCESS",
  GET_SCOUT_PLAYER_LEVELS: "GET_SCOUT_PLAYER_LEVELS",
  GET_SCOUT_PLAYER_LEVELS_SUCCESS: "GET_SCOUT_PLAYER_LEVELS_SUCCESS",
  GET_SCOUT_SKILLS: "GET_SCOUT_SKILLS",
  GET_SCOUT_SKILLS_SUCCESS: "GET_SCOUT_SKILLS_SUCCESS",
  GET_SCOUT_PLAYER_POSITIONS: "GET_SCOUT_PLAYER_POSITIONS",
  GET_SCOUT_PLAYER_POSITIONS_SUCCESS: "GET_SCOUT_PLAYER_POSITIONS_SUCCESS",
  GET_SCOUT_SELECTED_PLAYER_REPORT: "GET_SCOUT_SELECTED_PLAYER_REPORT",
  GET_SCOUT_SELECTED_PLAYER_REPORT_SUCCESS:
    "GET_SCOUT_SELECTED_PLAYER_REPORT_SUCCESS",
  GET_SCOUT_PLAYER_REPORTS: "GET_SCOUT_PLAYER_REPORTS",
  GET_SCOUT_PLAYER_REPORTS_SUCCESS: "GET_SCOUT_PLAYER_REPORTS_SUCCESS",
  GET_PLAYER_REPORT_HISTORY: "GET_PLAYER_REPORT_HISTORY",
  GET_PLAYER_REPORT_HISTORY_SUCCESS: "GET_PLAYER_REPORT_HISTORY_SUCCESS",
  SET_MANUAL_PLAYER_REPORT: "SET_MANUAL_PLAYER_REPORT",
  SELECT_PLAYER_REPORT: "SELECT_PLAYER_REPORT",
  SELECT_PLAYER_POSITION: "SELECT_PLAYER_POSITION",
  FILTER_PLAYER_REPORTS: "FILTER_PLAYER_REPORTS",
  FILTER_PLAYER_REPORTS_SUCCESS: "FILTER_PLAYER_REPORTS_SUCCESS",
  INIT_PLAYER_REPORT: "INIT_PLAYER_REPORT",
  CHANGE_PLAYER_REPORT: "CHANGE_PLAYER_REPORT",
  CHANGE_PLAYER_SKILL: "CHANGE_PLAYER_SKILL",
  SAVE_PLAYER_REPORT: "SAVE_PLAYER_REPORT",
  SAVE_PLAYER_REPORT_SUCCESS: "SAVE_PLAYER_REPORT_SUCCESS",
  DELETE_PLAYER_REPORT: "DELETE_PLAYER_REPORT",
  CHANGE_PLAYER_LEVEL: "CHANGE_PLAYER_LEVEL",
  CHANGE_PLAYER_FOOT: "CHANGE_PLAYER_FOOT",
  API_SCOUT_ERROR: "API_PREMIUM_ERROR",
  GET_AGENTS: "GET_AGENTS",
  GET_AGENTS_SUCCESS: "GET_AGENTS_SUCCESS",
  GET_AGENCIES: "GET_AGENCIES",
  GET_AGENCIES_SUCCESS: "GET_AGENCIES_SUCCESS",
  SAVE_AGENT: "SAVE_AGENT",
  GET_ALL_AGENCY_PLAYERS: "GET_ALL_AGENCY_PLAYERS",
  GET_AGENCY_PLAYERS: "GET_AGENCY_PLAYERS",
  GET_AGENCY_PLAYERS_SUCCESS: "GET_AGENCY_PLAYERS_SUCCESS",
  SAVE_AGENCY_PLAYER: "SAVE_AGENCY_PLAYERS",
  SAVE_AGENCY_PLAYER_SUCCESS: "SAVE_AGENCY_PLAYERS_SUCCESS",
  SAVE_AGENCY: "SAVE_AGENCY",
  GET_PLAYER_REPORT_COUNT: "GET_PLAYER_REPORT_COUNT",
  GET_PLAYER_REPORT_COUNT_SUCCESS: "GET_PLAYER_REPORT_COUNT_SUCCESS",
  GET_USER_SHADOW_TEAMS: "GET_USER_SHADOW_TEAMS",
  GET_USER_SHADOW_TEAMS_SUCCESS: "GET_USER_SHADOW_TEAMS_SUCCESS",
  SAVE_SHADOW_TEAM: "SAVE_SHADOW_TEAM",
  SAVE_SHADOW_TEAM_SUCCESS: "SAVE_SHADOW_TEAM_SUCCESS",
  SAVE_PLAYER_SHADOW_TEAM: "SAVE_PLAYER_SHADOW_TEAM",
  SAVE_PLAYER_SHADOW_TEAM_SUCCESS: "SAVE_PLAYER_SHADOW_TEAM_SUCCESS",
  SAVE_STAFF_SHADOW_TEAM: "SAVE_STAFF_SHADOW_TEAM",
  SAVE_STAFF_SHADOW_TEAM_SUCCESS: "SAVE_STAFF_SHADOW_TEAM_SUCCESS",
  GET_SHADOW_TEAM_STAFF: "GET_SHADOW_TEAM_STAFF",
  GET_SHADOW_TEAM_STAFF_SUCCESS: "GET_SHADOW_TEAM_STAFF_SUCCESS",
  GET_SHADOW_TEAM_PLAYERS: "GET_SHADOW_TEAM_PLAYERS",
  GET_SHADOW_TEAM_PLAYERS_SUCCESS: "GET_SHADOW_TEAM_PLAYERS_SUCCESS",
  GET_CLUB_TEAMS: "GET_CLUB_TEAMS",
  GET_CLUB_TEAMS_SUCCESS: "GET_CLUB_TEAMS_SUCCESS",
  GET_PLAYER_AGENCY_STATUS: "GET_PLAYER_AGENCY_STATUS",
  GET_PLAYER_AGENCY_STATUS_SUCCESS: "GET_PLAYER_AGENCY_STATUS_SUCCESS",
  DELETE_SHADOW_STAFF: "DELETE_SHADOW_STAFF",

  CLEAR_VIDEOS: "CLEAR_VIDEOS",
  GET_VIDEO_SELECTORS: "GET_VIDEO_SELECTORS",
  GET_VIDEO_SELECTORS_SUCCESS: "GET_VIDEO_SELECTORS_SUCCESS",
  GET_VIDEO_MATCH: "GET_VIDEO_MATCH",
  GET_VIDEOS_SUCCESS: "GET_VIDEOS_SUCCESS",
  GET_VIDEO_PLAYER_MATCH: "GET_VIDEO_PLAYER_MATCH",
  GET_VIDEO_PLAYER_MATCH_SUCCESS: "GET_VIDEO_PLAYER_MATCH_SUCCESS",
  GET_VIDEO_PLAYER_SELECTOR: "GET_VIDEO_PLAYER_SELECTOR",
  GET_VIDEO_PLAYER_SELECTOR_SUCCESS: "GET_VIDEO_PLAYER_SELECTOR_SUCCESS",

  clearVideos: () => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.CLEAR_VIDEOS,
      });
    };
  },

  //Video
  getVideoSelectors: () => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_VIDEO_SELECTORS,
      });
    };
  },
  getVideoSelectorsSuccess: (selectors) => ({
    type: actions.GET_VIDEO_SELECTORS_SUCCESS,
    selectors,
  }),

  getVideoPlayerBySelector: (uid_fed, uid_pla, selector) => {
    console.log("GET_VIDEOS_PLAYER BY SELECTOR");
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_VIDEO_PLAYER_SELECTOR,
        payload: { uid_fed, uid_pla, selector },
      });
    };
  },

  getMatchVideos: (uid_fed, uid_match) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_VIDEO_MATCH,
        payload: { uid_fed, uid_match },
      });
    };
  },

  getVideoPlayerByMatch: (uid_fed, uid_pla, uid_match) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_VIDEO_PLAYER_MATCH,
        payload: { uid_fed, uid_match, uid_pla },
      });
    };
  },

  getVideosSuccess: (videos) => ({
    type: actions.GET_VIDEOS_SUCCESS,
    videos,
  }),

  setNewPlayerManual: (currentReport) => {
    return (dispatch, getState) => {
      const skills = getState().Scout.playerSkills.map((s) => {
        s.uid = "";
        s.val = 0;
        return s;
      });
      dispatch({
        type: actions.INIT_PLAYER_REPORT,
        payload: { currentReport, skills },
      });
    };
  },
  setReportState: (currentReport) => {
    return (dispatch, getState) => {
      const skills = getState().Scout.playerSkills;
      dispatch({
        type: actions.INIT_PLAYER_REPORT,
        payload: { currentReport, skills },
      });
    };
  },
  deleteShadowStaff: (staff) => ({
    type: actions.DELETE_SHADOW_STAFF,
    payload: { staff },
  }),
  deleteShadowPlayer: (pla) => ({
    type: actions.DELETE_SHADOW_PLAYER,
    payload: { pla },
  }),
  deleteAgency: () => ({
    type: actions.DELETE_AGENCY,
  }),
  getPlayerAgencyStatus: () => ({
    type: actions.GET_PLAYER_AGENCY_STATUS,
  }),
  getPlayerAgencyStatusSuccess: (playerAgencyStatus) => ({
    type: actions.GET_PLAYER_AGENCY_STATUS_SUCCESS,
    playerAgencyStatus,
  }),
  getClubTeams: () => ({
    type: actions.GET_CLUB_TEAMS,
  }),
  getClubTeamsSuccess: (clubTeams) => ({
    type: actions.GET_CLUB_TEAMS_SUCCESS,
    clubTeams,
  }),
  getAgents: () => ({
    type: actions.GET_AGENTS,
  }),
  getAgentsSuccess: (agents) => ({
    type: actions.GET_AGENTS_SUCCESS,
    agents,
  }),
  getAgencies: () => ({
    type: actions.GET_AGENCIES,
  }),
  getAgenciesSuccess: (agencies) => ({
    type: actions.GET_AGENCIES_SUCCESS,
    agencies,
  }),
  getAllAgencyPlayers: (fecini, fecfin) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_ALL_AGENCY_PLAYERS,
        payload: { fecini, fecfin },
      });
    };
  },
  getAgencyPlayers: (uid) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_AGENCY_PLAYERS,
        payload: { uid },
      });
    };
  },
  getAgencyPlayersSuccess: (agencyPlayers) => ({
    type: actions.GET_AGENCY_PLAYERS_SUCCESS,
    agencyPlayers,
  }),
  saveAgent: (agent) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_AGENT,
        payload: { agent },
      });
    };
  },
  saveAgency: (agency) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_AGENCY,
        payload: { agency },
      });
    };
  },
  saveAgencyPlayer: (player) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_AGENCY_PLAYER,
        payload: { player },
      });
    };
  },
  getCurrentSeason: () => ({
    type: actions.GET_CURRENT_SEASON,
  }),
  getCurrentSeasonSuccess: (currentSeason) => ({
    type: actions.GET_USER_SHADOW_TEAMS_SUCCESS,
    currentSeason,
  }),
  getUserShadowTeams: () => ({
    type: actions.GET_USER_SHADOW_TEAMS,
  }),
  getUserShadowTeamsSuccess: (shadowTeams) => ({
    type: actions.GET_USER_SHADOW_TEAMS_SUCCESS,
    shadowTeams,
  }),
  getShadowTeamPlayers: (uid) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_SHADOW_TEAM_PLAYERS,
        payload: { uid },
      });
    };
  },
  getShadowTeamPlayersSuccess: (shadowTeamPlayers) => ({
    type: actions.GET_SHADOW_TEAM_PLAYERS_SUCCESS,
    shadowTeamPlayers,
  }),
  saveShadowTeam: (currentShadowTeam) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_SHADOW_TEAM,
        payload: { currentShadowTeam },
      });
    };
  },
  saveShadowTeamSuccess: (currentShadowTeam, shadowTeams) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_SHADOW_TEAM_SUCCESS,
        payload: { currentShadowTeam, shadowTeams },
      });
    };
  },
  savePlayerShadowTeam: (playerShadowTeam) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_PLAYER_SHADOW_TEAM,
        payload: { playerShadowTeam },
      });
    };
  },
  savePlayerShadowTeamSuccess: (shadowTeamPlayers) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_PLAYER_SHADOW_TEAM_SUCCESS,
        payload: { shadowTeamPlayers },
      });
    };
  },
  getShadowTeamStaff: (uid) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_SHADOW_TEAM_STAFF,
        payload: { uid },
      });
    };
  },
  getShadowTeamStaffSuccess: (shadowTeamStaff) => ({
    type: actions.GET_SHADOW_TEAM_STAFF_SUCCESS,
    shadowTeamStaff,
  }),
  saveStaffShadowTeam: (staffShadowTeam) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_STAFF_SHADOW_TEAM,
        payload: { staffShadowTeam },
      });
    };
  },
  saveStaffShadowTeamSuccess: (shadowTeamStaff) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SAVE_STAFF_SHADOW_TEAM_SUCCESS,
        payload: { shadowTeamStaff },
      });
    };
  },
  filterPlayerReports: (player_detail, report_skills) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.FILTER_PLAYER_REPORTS,
        payload: { player_detail, report_skills },
      });
    };
  },
  filterPlayerReportsSuccess: (reports) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_SCOUT_SELECTED_PLAYER_REPORT_SUCCESS,
        reports,
      });
    };
  },
  getScoutPlayerLevels: () => ({
    type: actions.GET_SCOUT_PLAYER_LEVELS,
  }),
  getScoutPlayerLevelsSuccess: (playerLevels) => ({
    type: actions.GET_SCOUT_PLAYER_LEVELS_SUCCESS,
    playerLevels,
  }),
  getScoutSkills: () => ({
    type: actions.GET_SCOUT_SKILLS,
  }),
  getScoutSkillsSuccess: (skills) => ({
    type: actions.GET_SCOUT_SKILLS_SUCCESS,
    skills,
  }),
  getScoutPlayerPositions: () => ({
    type: actions.GET_SCOUT_PLAYER_POSITIONS,
  }),
  getScoutPlayerPositionsSuccess: (positions) => ({
    type: actions.GET_SCOUT_PLAYER_POSITIONS_SUCCESS,
    positions,
  }),
  getScoutPlayersReports: () => ({
    type: actions.GET_SCOUT_PLAYER_REPORTS,
  }),
  getScoutPlayersReportsSuccess: (reports) => ({
    type: actions.GET_SCOUT_PLAYER_REPORTS_SUCCESS,
    reports,
  }),
  getScoutPlayerReport: (uid) => ({
    type: actions.GET_SCOUT_SELECTED_PLAYER_REPORT,
    payload: { uid },
  }),
  deletePlayerReport: (uid) => ({
    type: actions.DELETE_PLAYER_REPORT,
    payload: { uid },
  }),
  getScoutPlayerReportSuccess: (response) => {
    return (dispatch, getState) => {
      const selectedPosition =
        response.report.length > 0
          ? response.report[0].position
          : getState().Scout.playerPosition;
      const currentReport =
        response.report.length > 0
          ? response.report[0]
          : getState().Scout.currentReport;
      const skills = getState().Scout.skills;
      const playerSkills = (skills
        ? skills.filter((sk) => {
            return (
              sk.positions &&
              sk.positions !== null &&
              sk.positions.indexOf(selectedPosition) > -1
            );
          })
        : []
      ).map((s) => {
        if (response.skills && response.skills.length > 0) {
          const sr = response.skills.filter((rs) => {
            return rs.id_skill === s.id;
          });
          if (sr.length > 0) s.val = sr[0].val;
          else s.val = 0;
        }
        s.uid_report = currentReport.uid;
        return s;
      });
      dispatch({
        type: actions.GET_SCOUT_SELECTED_PLAYER_REPORT_SUCCESS,
        payload: { currentReport, playerSkills, selectedPosition },
      });
    };
  },
  getScoutReportsCountDate: (date) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_PLAYER_REPORT_COUNT,
        payload: { date },
      });
    };
  },

  getScoutReportsCountSuccess: (reportsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_PLAYER_REPORT_COUNT_SUCCESS,
        payload: { reportsCount },
      });
    };
  },
  getScoutPlayerReportHistory: (uid) => ({
    type: actions.GET_PLAYER_REPORT_HISTORY,
    payload: { uid },
  }),
  getScoutPlayerReportHistorySuccess: (playerReportHistory) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_PLAYER_REPORT_HISTORY_SUCCESS,
        payload: { playerReportHistory },
      });
    };
  },
  saveScoutReport: () => {
    return (dispatch, getState) => {
      const currentReport = getState().Scout.currentReport;
      let uid = "";
      if (currentReport.uid && currentReport.uid.length > 16)
        uid = currentReport.uid;
      const skills = getState()
        .Scout.playerSkills.filter((s) => {
          return s.val && s.val !== 0 && s.val !== null;
        })
        .map((s) => {
          return { id_skill: s.id, val: s.val, uid_report: uid };
        });
      dispatch({
        type: actions.SAVE_PLAYER_REPORT,
        payload: { currentReport, skills },
      });
    };
  },
  saveScoutReportSuccess: (report) => ({
    type: actions.SAVE_PLAYER_REPORT_SUCCESS,
    report,
  }),
  initPlayerReport: () => {
    return (dispatch, getState) => {
      const fed = getState().Premium.selected_type;
      const selectedCompetition = getState().Premium.selectedCompetition;
      const season = getState().Premium.selectedSeason;
      const pla = getState().Premium.selectedPlayer;
      const game = getState().Premium.selectedGame;
      const pos = getState().Scout.playerPosition;
      const skills = getState().Scout.playerSkills.map((s) => {
        s.uid = "";
        s.val = 0;
        return s;
      });
      const currentReport = {
        level: 0,
        nace: pla.nace || 1900,
        uid_pla: pla.uid_pla || "",
        uid_game: game.uid_game || "",
        uid_com: selectedCompetition[0].uid_com || "",
        txt: "",
        uid_tem: season[0].uid_tem || "",
        uid_fed: fed || "",
        nom_com: selectedCompetition[0].nom_com || "",
        tec: "",
        tac: "",
        fis: "",
        psi: "",
        nom_equ: pla.team || "",
        pla_name: pla.apodo || "",
        position: pos,
        pie: pla.foot === "right" ? 1 : pla.foot === "left" ? 2 : 3,
        game: game.nom_equ_loc
          ? `${game.nom_equ_loc}-${game.nom_equ_vis}`
          : "-",
      };

      dispatch({
        type: actions.INIT_PLAYER_REPORT,
        payload: { currentReport, skills },
      });
    };
  },
  selectPlayerReport: (report) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SELECT_PLAYER_REPORT,
        payload: { report },
      });
    };
  },
  selectPlayerPosition: (selectedPosition) => {
    return (dispatch, getState) => {
      //if exists previous skills first show these
      const previousSkills = getState().Scout.playerSkills;
      const skills = getState().Scout.skills;
      const currentReport = getState().Scout.currentReport;
      let playerSkills = skills.filter((sk) => {
        return (
          sk.positions &&
          sk.positions !== null &&
          sk.positions.indexOf(selectedPosition) > -1
        );
      });
      playerSkills = playerSkills.map((s) => {
        if (previousSkills && previousSkills.length > 0) {
          const sr = previousSkills.filter((rs) => {
            return rs.id_skill === s.id;
          });
          if (sr.length > 0) s.val = sr[0].val;
          else s.val = 0;
        }
        s.uid_report = currentReport ? currentReport.uid : "";
        return s;
      });

      if (currentReport) currentReport.position = selectedPosition;
      dispatch({
        type: actions.SELECT_PLAYER_POSITION,
        payload: { selectedPosition, playerSkills, currentReport },
      });
    };
  },
  changePlayerFoot: (foot) => {
    return (dispatch, getState) => {
      const currentReport = clone(getState().Scout.currentReport);
      currentReport.pie = foot;
      dispatch({
        type: actions.CHANGE_PLAYER_FOOT,
        payload: { currentReport },
      });
    };
  },
  changePlayerLevel: (level) => {
    return (dispatch, getState) => {
      const currentReport = clone(getState().Scout.currentReport);
      currentReport.level = level;
      dispatch({
        type: actions.CHANGE_PLAYER_REPORT,
        payload: { currentReport },
      });
    };
  },
  changePlayerReport: (tipo, val) => {
    return (dispatch, getState) => {
      const currentReport = clone(getState().Scout.currentReport);
      currentReport[tipo] = val;
      dispatch({
        type: actions.CHANGE_PLAYER_REPORT,
        payload: { currentReport },
      });
    };
  },
  changePlayerSkill: (skill, val) => {
    return (dispatch, getState) => {
      const playerSkills = getState().Scout.playerSkills.map((s) => {
        if (s.id === skill) s.val = val;
        return s;
      });

      dispatch({
        type: actions.CHANGE_PLAYER_SKILL,
        payload: { playerSkills },
      });
    };
  },

  getAPIError: () => ({
    type: actions.API_SCOUT_ERROR,
  }),
};
export default actions;
