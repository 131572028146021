import { getToken } from "./utility";
import config from "../../config/site.config";

import "isomorphic-fetch";

const API_ROOT = config.apiUrl;

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function callApi(endpoint) {
  const token = getToken().get("idToken");
  const fullUrl =
    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;
  return fetch(fullUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((res) => res.json())
    .then((res) => res)
    .then(
      (response) => ({ response }),
      (error) => ({ error: error.message || "Something bad happened" })
    );
}

function postApi(endpoint, data) {
  const token = getToken().get("idToken");
  const fullUrl =
    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  return fetch(fullUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => res)
    .then(
      (response) => ({ response }),
      (error) => {
        return { error: error.message || "Something bad happened" };
      }
    );
}

// api services
export const fetchFederations = () => callApi(`v1/scout/feds`);
export const fetchSeasons = (fed) => callApi(`v1/scout/seasons/${fed}`);
export const fetchCompetitions = (fed, tem) =>
  callApi(`v1/scout/com/${fed}/${tem}`);
export const fetchGroups = (fed, com) => callApi(`v1/scout/gru/${fed}/${com}`);
export const fetchStandings = (fed, com, gru) =>
  callApi(`v1/scout/table/${fed}/${com}/${gru}`);
export const fetchSquad = (fed, com, tem, team) =>
  callApi(`v1/scout/teamPlayers/${fed}/${com}/${tem}/${team}`);
export const fetchGroupGames = (fed, com, gru) =>
  callApi(`v1/scout/games/${fed}/${com}/${gru}`);
export const fetchOutstandingPlayersGroup = (fed, com, gru, pos) =>
  callApi(`v1/scout/outstandingGroup/${fed}/${com}/${gru}/${pos}`);
export const fetchPlayersGame = (fed, com, par) =>
  callApi(`v1/scout/gamePlayers/${fed}/${com}/${par}`);
export const fetchPlayersNextGame = (fed, com, equ_loc, equ_vis, par) =>
  callApi(
    `v1/scout/probablyPlayers/${fed}/${com}/${equ_loc}/${equ_vis}/${par}`
  );
export const fetchCurrentPlayerGames = (fed, uid_pla) =>
  callApi(`v1/scout/currentPlayerGames/${fed}/${uid_pla}`);
export const fetchPlayerHistory = (fed, uid_pla) =>
  callApi(`v1/scout/playerHistory/${fed}/${uid_pla}`);
export const fetchUserReports = () => callApi(`v1/scout/getReports`);
export const postPlayerReport = (data) => postApi(`v1/scout/saveReport`, data);
//export const fetchFavouritePlayers = (fed,uid_pla) => callApi(`v1/scout/getFav/${fed}/${uid_pla}`);
export const setFavouritePlayer = (data) => postApi(`v1/scout/addFav`, data);
export const removeFavouritePlayer = (data) => postApi(`v1/scout/delFav`, data);
export const searchFedPlayer = (uid_fed, name, teamname) =>
  callApi(`v2/scout/searchPlayer2/${uid_fed}/${name}/${teamname}`);
export const teamSearch = (uid_fed, uid_season, teamname) =>
  callApi(`v1/scout/teamSearch/${uid_fed}/${uid_season}/${teamname}`);

//premium
export const fetchBestLineup = (
  uid_fed,
  uid_com,
  uid_gru,
  uid_season,
  minutes,
  year,
  minlevel
) =>
  callApi(
    `v2/com/bestLineup/${uid_fed}/${uid_com}/${uid_gru}/${uid_season}/${minutes}/${year}/${minlevel}`
  );
export const fetchPremiumTypes = () => callApi(`v2/com/premiumTypes`);
export const fetchPremiumCompetitions = (uid_fed) =>
  callApi(`v2/com/listCompetitions/${uid_fed}`);
export const fetchPremiumGroups = (uid_fed, uid_com, uid_tem) =>
  callApi(`v2/com/listGroups/${uid_fed}/${uid_com}/${uid_tem}`);
export const fetchPremiumStandings = (uid_fed, uid_com, uid_gru, uid_season) =>
  callApi(`v2/com/standings/${uid_fed}/${uid_com}/${uid_gru}/${uid_season}`);
export const fetchPremiumGroupGames = (uid_fed, uid_com, uid_gru, uid_season) =>
  callApi(`v2/com/groupGames/${uid_fed}/${uid_com}/${uid_gru}/${uid_season}`);
export const fetchPremiumTeamPlayers = (
  uid_fed,
  uid_com,
  uid_gru,
  uid_team,
  uid_season
) =>
  callApi(
    `v2/com/getTeamPlayers/${uid_fed}/${uid_com}/${uid_gru}/${uid_team}/${uid_season}`
  );
export const fetchPremiumCompetitionPlayers = (
  uid_fed,
  uid_com,
  uid_season,
  pos
) =>
  callApi(
    `v2/com/competitionPlayers/${uid_fed}/${uid_com}/${uid_season}/${pos}`
  );
export const fetchPremiumPlayerStats = (
  uid_fed,
  uid_com,
  uid_pla,
  uid_season
) =>
  callApi(`v2/com/playerStats/${uid_fed}/${uid_com}/${uid_pla}/${uid_season}`);
export const fetchPremiumPlayerStatsPosition = (uid_fed, uid_pla, pos) =>
  callApi(`v2/com/playerStatsPosition/${uid_fed}/${uid_pla}/${pos}`);

export const fetchOutstandingDashboard = (filter) =>
  callApi(
    `v2/com/outstandingPlayersDashboard/${filter.days}/${filter.min}/${filter.year[0]}/${filter.year[1]}`
  );

//competitionStatsPositionSeason
export const fetchCompetitionStatsByPosition = (uid_fed, pos, season) =>
  callApi(`v2/com/competitionStatsPositionSeason/${uid_fed}/${pos}/${season}`);

export const fetchPremiumPlayerStatsPositionSeason = (
  uid_fed,
  uid_pla,
  pos,
  season,
  fromDate,
  toDate
) =>
  season !== "_"
    ? fromDate && fromDate !== null
      ? callApi(
          `v2/com/playerStatsPositionSeasonFromDate/${uid_fed}/${uid_pla}/${pos}/${season}/${fromDate}/${toDate}`
        )
      : callApi(
          `v2/com/playerStatsPositionSeason/${uid_fed}/${uid_pla}/${pos}/${season}`
        )
    : callApi(`v2/com/playerStatsPosition/${uid_fed}/${uid_pla}/${pos}`);
export const fetchPremiumGroupPlayers = (
  uid_fed,
  uid_com,
  uid_gru,
  uid_season,
  pos
) =>
  callApi(
    `v2/com/groupPlayers/${uid_fed}/${uid_com}/${uid_gru}/${uid_season}/${pos}`
  );
export const fetchPremiumReport = (uid_fed, uid_com, uidpar) =>
  callApi(`v2/com/report/${uid_fed}/${uid_com}/${uidpar}`);
export const fetchPremiumPlayerGames = (
  uid_fed,
  uid_com,
  uid_pla,
  uid_season
) =>
  callApi(`v2/com/playerGames/${uid_fed}/${uid_com}/${uid_pla}/${uid_season}`);
export const fetchPremiumPlayerGamesV2 = (uid_fed, uid_pla) =>
  callApi(`v2/com/playerGamesV2/${uid_fed}/${uid_pla}`);

export const fetchPremiumPlayerDetail = (uid_fed, uid_pla, uid_season) =>
  callApi(`v2/com/playerDetail/${uid_fed}/${uid_pla}/${uid_season}`);
export const searchPlayer = (uid_fed, name) =>
  callApi(`v2/com/searchPlayer/${uid_fed}/${name}`);

export const globalSearch = (uid_fed, name, team, years) =>
  callApi(
    `v2/scout/globalSearch/${uid_fed}/${name}${
      team ? "/" + team + "/" + years[0] + "/" + years[1] : ""
    }`
  );

export const fetchPlayerCareer = (uid_fed, uid_pla) =>
  callApi(`v2/scout/career/${uid_fed}/${uid_pla}`);

export const fetchNextPlayerGames = (uid_fed, uid_pla) =>
  callApi(`v2/scout/nextPlayerGames/${uid_fed}/${uid_pla}`);

//scout
export const fetchGetPlayerSkills = () => callApi(`v2/scout/getPlayerSkills`);
export const fetchGetPlayersLevels = () => callApi(`v2/scout/getPlayersLevels`);
export const fetchGetPlayersPositions = () =>
  callApi(`v2/scout/getPlayersPositions`);
export const fetchUserPlayersReports = () =>
  callApi(`v2/scout/getResumeReports`);
export const fetchSelectedPlayerReportsHistory = (uid_pla) =>
  callApi(`v2/scout/getPlayerReportHistory/${uid_pla}`);
export const fetchSelectedPlayerReport = (uid_pla) =>
  callApi(`v2/scout/getPlayerReport/${uid_pla}`);
export const deletePlayerReport = (uid) =>
  callApi(`v1/scout/deleteReport/${uid}`);
export const postPlayerReport2 = (data) => postApi(`v2/scout/saveReport`, data);
export const deleteFavouriteCompetition = (uid_gru) =>
  callApi(`v2/scout/deleteFavouriteCompetition/${uid_gru}`);
export const addFavouriteCompetition = (data) =>
  postApi(`v2/scout/addFavouriteCompetition`, data);
export const fetchFavouriteCompetitions = (data) =>
  callApi(`v2/scout/getFavouriteCompetitions`);
export const addFavouritePlayer = (data) =>
  postApi(`v2/scout/addFavouritePlayer`, data);
export const fetchFavouritePlayers = (data) =>
  callApi(`v2/scout/getFavouritePlayers`);
export const addFavouriteTeam = (data) =>
  postApi(`v2/scout/addFavouriteTeam`, data);
export const fetchFavouriteTeams = (data) =>
  callApi(`v2/scout/getFavouriteTeams`);

export const fetchPlayerReportsFiltered = (data) =>
  postApi(`v2/scout/filterReports`, data);

export const saveAgency = (data) => postApi(`v2/scout/saveAgency`, data);
export const saveAgent = (data) => postApi(`v2/scout/saveAgent`, data);
export const savePlayerAgent = (data) =>
  postApi(`v2/scout/savePlayerAgent`, data);
export const fetchAgencies = () => callApi(`v2/scout/getAgencies`);
export const fetchAgents = () => callApi(`v2/scout/getAgents`);
export const fetchAgencyPlayers = (uid) =>
  callApi(`v2/scout/getAgencyPlayers/${uid}`);
export const fetchAllAgenciesPlayers = (fecini, fecfin) =>
  callApi(`v2/scout/getAllAgencyPlayers/${fecini}/${fecfin}`);
export const fetchClubTeams = () => callApi(`v2/scout/getClubTeams`);
export const fetchPlayerAgencyStatus = () =>
  callApi(`v2/scout/getPlayerAgencyStatus`);
export const deleteAgency = (uid) => callApi(`v2/scout/deleteAgency/${uid}`);

export const deleteShadowPlayer = (uid_pla, uid_ran) =>
  callApi(`v2/scout/deleteShadowPlayer/${uid_pla}/${uid_ran}`);
export const deleteShadowStaff = (uid, uid_ran) =>
  callApi(`v2/scout/deleteShadowStaff/${uid}/${uid_ran}`);
export const updateShadowTeam = (data) =>
  postApi(`v2/scout/saveShadowTeam`, data);
export const getUserShadowTeams = (data) =>
  callApi(`v2/scout/userRankings`, data);
export const updatePlayerRanking = (data) =>
  postApi(`v2/scout/savePlayerShadowTeam`, data);
export const updateRankingStaff = (data) =>
  postApi(`v2/scout/saveStaffShadowTeam`, data);
export const getShadowTeamStaff = (uid_ranking) =>
  callApi(`v2/scout/staffShadowTeam/${uid_ranking}`);
export const getShadowTeamPlayers = (uid_ranking) =>
  callApi(`v2/scout/playersShadowTeam/${uid_ranking}`);
export const fetchReportsCountByUser = (fec) =>
  callApi(`v2/scout/GetReportsCountByUser/${fec}`);
export const fetchReportsCountByCom = (fec) =>
  callApi(`v2/scout/GetReportsCountByCom/${fec}`);
export const fetchReportsCountByLevel = (fec) =>
  callApi(`v2/scout/GetReportsCountByLevel/${fec}`);
export const fetchReportsCountByPosition = (fec) =>
  callApi(`v2/scout/GetReportsCountByPosition/${fec}`);
export const fetchCurrentSeason = () => callApi(`v2/scout/getCurrentSeason`);

export const fetchVideoPlayerEventsTypes = () =>
  callApi(`v2/scout/videoPlayerTypeEvents/`);

export const fetchVideoPlayerEventsBySelector = (uid_fed, uid_pla, selector) =>
  callApi(`v2/scout/videoPlayerBySelector/${uid_fed}/${uid_pla}/${selector}`);

export const fetchVideoPlayerEventsByGame = (uid_fed, uid_match, uid_pla) =>
  callApi(`v2/scout/playerVideoMatch/${uid_fed}/${uid_match}/${uid_pla}`);

export const fetchVideoMatch = (uid_fed, uid_match) =>
  callApi(`v2/scout/matchVideo/${uid_fed}/${uid_match}`);

export const updateCurrentPassword = (data) =>
  postApi(`v2/auth/changePass`, data);

//WELLNESS
export const fetchSessions = (date) =>
  callApi(`v2/wellness/getTrainingSessions/${date}`);
export const fetchSessionPlayers = (uid) =>
  callApi(`v2/wellness/sessionPlayers/${uid}`);

export const fetchQuestions = () => callApi(`v2/wellness/getWellnessQuestions`);
export const fetchBodyPoints = () => callApi(`v2/wellness/getWellnessBody`);
export const fetchAnswers = (uid) =>
  callApi(`v2/wellness/getWellnessAnswers/${uid}`);
export const fetchHurts = (uid) =>
  callApi(`v2/wellness/getWellnessHurts/${uid}`);

export const fetchFullAnswers = (uid) =>
  callApi(`v2/wellness/getFullWellnessAnswers/${uid}`);
export const fetchFullHurts = (uid) =>
  callApi(`v2/wellness/getFullWellnessHurts/${uid}`);

export const saveSessionPlayer = (data) =>
  postApi(`v2/wellness/togglePlayerSession`, data);
export const saveAnswer = (data) =>
  postApi(`v2/wellness/saveWellnessAnswer`, data);
export const saveHurt = (data) => postApi(`v2/wellness/saveWellnessHurt`, data);
export const saveSession = (data) =>
  postApi(`v2/wellness/saveTrainingSession`, data);
export const deleteSession = (uid) =>
  callApi(`v2/wellness/deleteTrainingSession/${uid}`);

export const fetchWellnessAnswersByDateRange = (dates) =>
  callApi(`v2/wellness/getAnswersBySessionDateRange/${dates[0]}/${dates[1]}`);
