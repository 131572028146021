import cloneDeep from "lodash/cloneDeep";

const actions = {
  CLEAN_STATS: "CLEAN_STATS",
  GLOBAL_SEARCH: "GLOBAL_SEARCH",
  GLOBAL_SEARCH_SUCCESS: "GLOBAL_SEARCH_SUCCESS",
  GET_NEXT_PLAYER_GAMES: "GET_NEXT_PLAYER_GAMES",
  GET_NEXT_PLAYER_GAMES_SUCCESS: "GET_NEXT_PLAYER_GAMES_SUCCESS",
  GET_PLAYER_CAREER: "GET_PLAYER_CAREER",
  GET_PLAYER_CAREER_SUCCESS: "GET_PLAYER_CAREER_SUCCESS",
  SET_MANUAL_PLAYER: "SET_MANUAL_PLAYER",
  GET_PREMIUM_TYPES: "GET_PREMIUM_TYPES",
  GET_PREMIUM_PLAYER_GAMESV2: "GET_PREMIUM_PLAYER_GAMESV2",
  GET_PREMIUM_TYPES_SUCCESS: "GET_PREMIUM_TYPES_SUCCESS",
  GET_FAVOURITE_COMPETITIONS: "GET_FAVOURITE_COMPETITIONS",
  GET_FAVOURITE_COMPETITIONS_SUCCESS: "GET_FAVOURITE_COMPETITIONS_SUCCESS",
  GET_FAVOURITE_PLAYERS: "GET_FAVOURITE_PLAYERS",
  GET_FAVOURITE_PLAYERS_SUCCESS: "GET_FAVOURITE_PLAYERS_SUCCESS",
  GET_FAVOURITE_TEAMS: "GET_FAVOURITE_TEAMS",
  GET_FAVOURITE_TEAMS_SUCCESS: "GET_FAVOURITE_TEAMS_SUCCESS",
  GET_PREMIUM_COMPETITIONS: "GET_PREMIUM_COMPETITIONS",
  GET_PREMIUM_COMPETITIONS_SUCCESS: "GET_PREMIUM_COMPETITIONS_SUCCESS",
  GET_COMPETITION_GROUPS: "GET_COMPETITION_GROUPS",
  GET_PREMIUM_GROUPS: "GET_PREMIUM_GROUPS",
  GET_PREMIUM_GROUPS_SUCCESS: "GET_PREMIUM_GROUPS_SUCCESS",
  GET_PREMIUM_BEST_LINEUP: "GET_PREMIUM_BEST_LINEUP",
  GET_PREMIUM_BEST_LINEUP_SUCCESS: "GET_PREMIUM_BEST_LINEUP_SUCCESS",
  GET_PREMIUM_STANDINGS: "GET_PREMIUM_STANDINGS",
  GET_PREMIUM_STANDINGS_SUCCESS: "GET_PREMIUM_STANDINGS_SUCCESS",
  GET_PREMIUM_GAMES: "GET_PREMIUM_GAMES",
  GET_PREMIUM_GAMES_SUCCESS: "GET_PREMIUM_GAMES_SUCCESS",
  GET_PREMIUM_COMPETITION_PLAYERS: "GET_PREMIUM_COMPETITION_PLAYERS",
  GET_PREMIUM_COMPETITION_PLAYERS_SUCCESS:
    "GET_PREMIUM_COMPETITION_PLAYERS_SUCCESS",
  GET_PREMIUM_PLAYER_STATS_POSITION: "GET_PREMIUM_PLAYER_STATS_POSITION",
  GET_PREMIUM_PLAYER_STATS: "GET_PREMIUM_PLAYER_STATS",
  GET_PREMIUM_PLAYER_STATS_SUCCESS: "GET_PREMIUM_PLAYER_STATS_SUCCESS",
  GET_PREMIUM_TEAM_PLAYERS: "GET_PREMIUM_TEAM_PLAYERS",
  GET_PREMIUM_TEAM_PLAYERS_SUCCESS: "GET_PREMIUM_TEAM_PLAYERS_SUCCESS",
  GET_PREMIUM_PLAYER_GAMES: "GET_PREMIUM_PLAYER_GAMES",
  GET_PREMIUM_PLAYER_GAMES_SUCCESS: "GET_PREMIUM_PLAYER_GAMES_SUCCESS",
  GET_PREMIUM_GROUP_PLAYERS: "GET_PREMIUM_GROUP_PLAYERS",
  GET_PREMIUM_GROUP_PLAYERS_SUCCESS: "GET_PREMIUM_COMPETITION_PLAYERS_SUCCESS",
  GET_PREMIUM_GAME_REPORT: "GET_PREMIUM_GAME_REPORT",
  GET_PREMIUM_GAME_REPORT_SUCCESS: "GET_PREMIUM_GAME_REPORT_SUCCESS",
  GET_PREMIUM_PLAYER_DETAIL: "GET_PREMIUM_PLAYER_DETAIL",
  GET_PREMIUM_PLAYER_DETAIL_SUCCESS: "GET_PREMIUM_PLAYER_DETAIL_SUCCESS",
  GET_PREMIUM_OUTSTANDINGDASHBOARD: "GET_PREMIUM_OUTSTANDINGDASHBOARD",
  GET_PREMIUM_OUTSTANDINGDASHBOARD_SUCCESS:
    "GET_PREMIUM_OUTSTANDINGDASHBOARD_SUCCESS",
  SET_FAVOURITE_COMPETITION: "SET_FAVOURITE_COMPETITION",
  REMOVE_FAVOURITE_COMPETITION: "REMOVE_FAVOURITE_COMPETITION",
  SET_FAVOURITE_COMPETITION_SUCCESS: "SET_FAVOURITE_COMPETITION_SUCCESS",
  SET_FAVOURITE_PLAYER: "SET_FAVOURITE_PLAYER",
  SET_FAVOURITE_PLAYER_SUCCESS: "SET_FAVOURITE_PLAYER_SUCCESS",
  SET_FAVOURITE_TEAM: "SET_FAVOURITE_TEAM",
  SET_FAVOURITE_TEAM_SUCCESS: "SET_FAVOURITE_TEAM_SUCCESS",
  SEARCH_PLAYER: "SEARCH_PLAYER",
  SEARCH_PLAYER_SUCCESS: "SEARCH_PLAYER_SUCCESS",
  SELECT_SEASON: "SELECT_SEASON",
  SELECT_COUNTRY: "SELECT_COUNTRY",
  SELECT_COMPETITION: "SELECT_COMPETITION",
  SELECT_GROUP: "SELECT_GROUP",
  SELECT_PLAYER: "SELECT_PLAYER",
  REMOVE_PLAYER_STAT: "REMOVE_PLAYER_STAT",
  CHANGE_ACTIVE_PLAYER_STAT: "CHANGE_ACTIVE_PLAYER_STAT",
  CHANGE_TEAM_COLOR: "CHANGE_TEAM_COLOR",
  API_PREMIUM_ERROR: "API_PREMIUM_ERROR",
  getNextPlayerGames: (uid_fed, uid_pla) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_NEXT_PLAYER_GAMES,
        payload: { uid_pla, uid_fed },
      });
    };
  },
  getNextPlayerGamesSuccess: (nextGames) => ({
    type: actions.GET_NEXT_PLAYER_GAMES_SUCCESS,
    nextGames,
  }),

  getCompetitionLineup: (
    uid_fed,
    uid_com,
    uid_gru,
    uid_season,
    minutes,
    year,
    level
  ) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_PREMIUM_BEST_LINEUP,
        payload: {
          uid_fed,
          uid_com,
          uid_gru,
          uid_season,
          minutes,
          year,
          level,
        },
      });
    };
  },
  getBestLineupSuccess: (bestLineup) => ({
    type: actions.GET_PREMIUM_BEST_LINEUP_SUCCESS,
    bestLineup,
  }),

  getPlayerCareer: (uid_fed, uid_pla) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_PLAYER_CAREER,
        payload: { uid_pla, uid_fed },
      });
    };
  },
  getPlayerCareerSuccess: (career) => ({
    type: actions.GET_PLAYER_CAREER_SUCCESS,
    career,
  }),
  setManualPlayer: (manualPlayer) => {
    return (dispatch) => {
      const selectedPlayer = {
        apodo: manualPlayer.pla_name,
        uid_pla: manualPlayer.uid_pla,
        nace: manualPlayer.nace,
        team: manualPlayer.nom_equ,
        birth_country: "-",
        foot: "",
        pos_id: manualPlayer.position,
        markt_value: 0,
        contract_expires: "01/01/1900",
      };
      dispatch({
        type: actions.SET_MANUAL_PLAYER,
        payload: { selectedPlayer },
      });
    };
  },

  changeTeamColor: (team, nc, color) => {
    return (dispatch, getState) => {
      const game = getState().Premium.game;
      if (team === "home") {
        game.home.color.bg1 = nc === 1 ? color : game.home.color.bg1;
        game.home.color.bg2 = nc === 2 ? color : game.home.color.bg2;
        game.home.color.text = nc === 3 ? color : game.home.color.text;
      } else {
        game.away.color.bg1 = nc === 1 ? color : game.away.color.bg1;
        game.away.color.bg2 = nc === 2 ? color : game.away.color.bg2;
        game.away.color.text = nc === 3 ? color : game.away.color.text;
      }

      dispatch({
        type: actions.CHANGE_TEAM_COLOR,
        game,
      });
    };
  },

  saveFavouriteCompetition: (favouriteCompetition) => {
    return (dispatch, getState) => {
      const groups = getState().Premium.groups.map((g) => {
        if (favouriteCompetition.uid_gru === g.uid_gru) g.isFav = 1;
        return g;
      });
      dispatch({
        type: actions.SET_FAVOURITE_COMPETITION,
        payload: { favouriteCompetition, groups },
      });
    };
  },
  setPremiumFavouriteCompetition: () => {
    return (dispatch, getState) => {
      const fed = getState().Premium.premiumTypes.filter((f) => {
        return f.uid_fed === getState().Premium.selected_type;
      });
      const currentCompetition = getState().Premium.selectedCompetition[0];
      const selectedSeason = getState().Premium.selectedSeason[0];
      const selectedCountry = getState().Premium.selectedCountry;
      const selectedGroup = getState().Premium.selectedGroup[0];
      const groups = getState().Premium.groups.map((g) => {
        if (selectedGroup.uid_gru === g.uid_gru) g.isFav = 1;
        return g;
      });
      const favouriteCompetition = {
        uid_fed: fed[0].uid_fed,
        uid_com: currentCompetition.uid_com,
        uid_gru: selectedGroup.uid_gru,
        nom_fed: fed[0].nom_web,
        uid_tem: selectedSeason.uid_tem,
        nom_com: currentCompetition.nom_com,
        nom_gru: selectedGroup.nom_gru,
        uid_country: selectedCountry.uid_country,
        country: selectedCountry.country_name,
      };

      dispatch({
        type: actions.SET_FAVOURITE_COMPETITION,
        payload: { favouriteCompetition, groups },
      });
    };
  },
  deleteFavouriteCompetition: (uid_gru) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.REMOVE_FAVOURITE_COMPETITION,
        payload: { uid_gru },
      });
    };
  },
  setPremiumFavouriteCompetitionSuccess: () => ({
    type: actions.SET_FAVOURITE_COMPETITION_SUCCESS,
  }),
  getFavouriteCompetitions: () => ({
    type: actions.GET_FAVOURITE_COMPETITIONS,
  }),
  getFavouriteCompetitionsSuccess: (favouriteCompetitions) => ({
    type: actions.GET_FAVOURITE_COMPETITIONS_SUCCESS,
    favouriteCompetitions,
  }),
  setPremiumFavouritePlayer: (pla) => {
    return (dispatch, getState) => {
      const fed = getState().Premium.premiumTypes.filter((f) => {
        return f.uid_fed === getState().Premium.selected_type;
      });
      const currentCompetition = getState().Premium.selectedCompetition[0];
      const selectedSeason = getState().Premium.selectedSeason[0];
      const selectedTeam = getState().Premium.selectedTeam;
      const teamPlayers = getState().Premium.teamPlayers.map((p) => {
        if (pla.uid_pla === p.uid_pla) p.isFav = 1;
        return p;
      });
      const favouritePlayer = {
        uid_fed: fed[0].uid_fed,
        uid_com: currentCompetition.uid_com,
        uid_tem: selectedSeason.uid_tem,
        com_name: currentCompetition.nom_com,
        uid_team: selectedTeam.uid_team,
        uid_pla: pla.uid_pla,
        pla_name: pla.apodo,
        team_name: selectedTeam.equ_nom,
        nace: pla.nace || 0,
        pos: pla.pos_id || 0,
      };
      dispatch({
        type: actions.SET_FAVOURITE_PLAYER,
        payload: { favouritePlayer, teamPlayers },
      });
    };
  },
  setPremiumFavouritePlayerSuccess: () => ({
    type: actions.SET_FAVOURITE_PLAYER_SUCCESS,
  }),
  getFavouritePlayers: () => ({
    type: actions.GET_FAVOURITE_PLAYERS,
  }),
  getFavouritePlayersSuccess: (favouritePlayers) => ({
    type: actions.GET_FAVOURITE_PLAYERS_SUCCESS,
    favouritePlayers,
  }),
  setPremiumFavouriteTeam: () => {
    return (dispatch, getState) => {
      const fed = getState().Premium.premiumTypes.filter((f) => {
        return f.uid_fed === getState().Premium.selected_type;
      });
      const currentCompetition = getState().Premium.selectedCompetition[0];
      const selectedSeason = getState().Premium.selectedSeason[0];
      const selectedTeam = getState().Premium.selectedTeam;
      selectedTeam.isFav = 1;
      const favouriteTeam = {
        uid_fed: fed[0].uid_fed,
        uid_com: currentCompetition.uid_com,
        uid_tem: selectedSeason.uid_tem,
        com_name: currentCompetition.nom_com,
        uid_team: selectedTeam.uid_team,
        team_name: selectedTeam.equ_nom,
      };
      dispatch({
        type: actions.SET_FAVOURITE_TEAM,
        payload: { favouriteTeam, selectedTeam },
      });
    };
  },
  setPremiumFavouriteTeamSuccess: () => ({
    type: actions.SET_FAVOURITE_TEAM_SUCCESS,
  }),
  getFavouriteTeams: () => ({
    type: actions.GET_FAVOURITE_TEAMS,
  }),
  getFavouriteTeamSuccess: (favouriteTeams) => ({
    type: actions.GET_FAVOURITE_TEAMS_SUCCESS,
    favouriteTeams,
  }),
  getPremiumTypes: () => ({
    type: actions.GET_PREMIUM_TYPES,
  }),
  getPremiumTypesSuccess: (premiumTypes) => ({
    type: actions.GET_PREMIUM_TYPES_SUCCESS,
    premiumTypes,
  }),

  cleanStats: () => {
    return (dispatch, getState) => {
      let radarEchartOptions = cloneDeep(getState().Premium.radarEchartOptions);
      radarEchartOptions.radar[0].indicator = [];
      radarEchartOptions.legend.data = [];
      radarEchartOptions.series[0].data = [];
      dispatch({
        type: actions.CLEAN_STATS,
        payload: { radarEchartOptions },
      });
    };
  },
  getPremiumCompetitions: (selected_type) => {
    return (dispatch, getState) => {
      let radarEchartOptions = cloneDeep(getState().Premium.radarEchartOptions);
      radarEchartOptions.radar[0].indicator = [];
      radarEchartOptions.legend.data = [];
      radarEchartOptions.series[0].data = [];
      dispatch({
        type: actions.GET_PREMIUM_COMPETITIONS,
        payload: { selected_type, radarEchartOptions },
      });
    };
  },
  getPremiumCompetitionsSuccess: (
    competitions,
    filteredCompetitions,
    countries,
    seasons
  ) => ({
    type: actions.GET_PREMIUM_COMPETITIONS_SUCCESS,
    competitions,
    filteredCompetitions,
    countries,
    seasons,
  }),

  getOutstandingDashboard: (filter) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_PREMIUM_OUTSTANDINGDASHBOARD,
        payload: { filter },
      });
    };
  },
  getOutstandingDashboardsSuccess: (outstandingDashboard) => ({
    type: actions.GET_PREMIUM_OUTSTANDINGDASHBOARD_SUCCESS,
    payload: { outstandingDashboard },
  }),

  getCompetitionGroups: (selected_fed, selected_com, selected_season) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_COMPETITION_GROUPS,
        payload: { selected_fed, selected_com, selected_season },
      });
    };
  },

  getPremiumGroups: (selected_com) => {
    return (dispatch, getState) => {
      const fed = getState().Premium.selected_type;
      const selectedCom = getState().Premium.competitions.filter((c) => {
        return c.uid_tem === selected_com;
      });
      dispatch({
        type: actions.SELECT_SEASON,
        payload: { selectedCom, fed },
      });
    };
  },
  getPremiumGroupsSuccess: (groups) => ({
    type: actions.GET_PREMIUM_GROUPS_SUCCESS,
    groups,
  }),
  getGroupGames: () => {
    return (dispatch, getState) => {
      const selectedSeason = getState().Premium.selectedSeason;
      const fed = getState().Premium.selected_type;
      const selectedCompetition = getState().Premium.selectedCompetition;
      const selectedGroup = getState().Premium.selectedGroup;
      dispatch({
        type: actions.GET_PREMIUM_GAMES,
        payload: { selectedSeason, selectedCompetition, fed, selectedGroup },
      });
    };
  },
  getGroupGamesSuccess: (games) => ({
    type: actions.GET_PREMIUM_GAMES_SUCCESS,
    games,
  }),
  getGameReport: (selectedGame) => {
    return (dispatch, getState) => {
      const fed = getState().Premium.selected_type;
      const selectedCompetition = getState().Premium.selectedCompetition;
      const game = getState().Premium.game;
      dispatch({
        type: actions.GET_PREMIUM_GAME_REPORT,
        payload: { selectedGame, fed, selectedCompetition, game },
      });
    };
  },
  getGameReportSuccess: (game) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_PREMIUM_GAME_REPORT_SUCCESS,
        game: game,
      });
    };
  },
  getGroupStandings: () => {
    return (dispatch, getState) => {
      const selectedSeason = getState().Premium.selectedSeason;
      const fed = getState().Premium.selected_type;
      const selectedCompetition = getState().Premium.selectedCompetition;
      const selectedGroup = getState().Premium.selectedGroup;
      dispatch({
        type: actions.GET_PREMIUM_STANDINGS,
        payload: { selectedCompetition, selectedGroup, selectedSeason, fed },
      });
    };
  },

  getGroupStandingsSuccess: (standings) => ({
    type: actions.GET_PREMIUM_STANDINGS_SUCCESS,
    standings,
  }),

  getCompetitionPlayers: (position) => {
    return (dispatch, getState) => {
      const selectedSeason = getState().Premium.selectedSeason;
      const fed = getState().Premium.selected_type;
      const selectedCompetition = getState().Premium.selectedCompetition;
      dispatch({
        type: actions.GET_PREMIUM_COMPETITION_PLAYERS,
        payload: { selectedCompetition, selectedSeason, fed, position },
      });
    };
  },

  getCompetitionPlayersSuccess: (competitionPlayers) => ({
    type: actions.GET_PREMIUM_COMPETITION_PLAYERS_SUCCESS,
    competitionPlayers,
  }),
  getPlayerStatsPosition: (
    withCompetition,
    player,
    pos,
    season,
    fed,
    fromDate,
    toDate
  ) => {
    console.log(`FromDate: ${fromDate}`);
    return (dispatch, getState) => {
      if (!fed || fed == null) fed = getState().Premium.selected_type;
      dispatch({
        type: actions.GET_PREMIUM_PLAYER_STATS_POSITION,
        payload: {
          fed,
          player,
          pos,
          season,
          withCompetition,
          fromDate,
          toDate,
        },
      });
    };
  },
  getPlayerStats: (player) => {
    return (dispatch, getState) => {
      const selectedSeason = getState().Premium.selectedSeason;
      const fed = getState().Premium.selected_type;
      const selectedCompetition = getState().Premium.selectedCompetition;
      const season = "_";
      dispatch({
        type: actions.GET_PREMIUM_PLAYER_STATS,
        payload: { selectedCompetition, selectedSeason, fed, player, season },
      });
    };
  },
  getPlayerStatsSuccess: (payload) => {
    return (dispatch, getState) => {
      const colors = [
        "rgb(255,58,59)",
        "rgb(0,150, 0)",
        "rgb(71,119,245)",
        "rgb(225,	211, 15)",
        "rgb(61,	199, 188)",
        "rgb(94,	20, 122)",
      ];
      let playerStats = cloneDeep(getState().Premium.playerStats);
      let radarEchartOptions = cloneDeep(getState().Premium.radarEchartOptions);
      let competitionStats = cloneDeep(getState().Premium.competitionStats);

      let originalStats = cloneDeep(getState().Premium.originalStats);

      if (
        payload.seasonData &&
        payload.seasonData.length > 0 &&
        payload.withCompetition &&
        payload.season.season_name !== "_" &&
        competitionStats.indexOf(payload.season.uid_season) < 0
      ) {
        competitionStats.push(payload.season.uid_season);
        // Add competition Stats
        payload.seasonData.forEach((e) => {
          const curidx = radarEchartOptions.radar[0].indicator.findIndex(
            (es) => {
              return es.name === e.name;
            }
          );
          if (curidx > -1) {
            //Exists, check if current value is lower and ensure is the same index
            if (
              parseFloat(radarEchartOptions.radar[0].indicator[curidx].max) <
              parseFloat(e.x90min)
            ) {
              if (parseFloat(e.x90min) < 1) {
                radarEchartOptions.radar[0].indicator[curidx].max =
                  parseFloat(e.x90min) + 0.1;
              } else {
                radarEchartOptions.radar[0].indicator[curidx].max =
                  parseFloat(e.x90min) + 1;
              }
            }
          } else {
            //Not exists indicator - Add the new stat, and new values as 0 for players we had before
            radarEchartOptions.radar[0].indicator.push({
              color: "black",
              name: e.name,
              max:
                parseFloat(e.x90min) < 1
                  ? parseFloat(e.x90min) + 0.1
                  : parseFloat(e.x90min) + 1,
            });

            radarEchartOptions.series[0].data.map((d) => {
              d.value.push(0);
              return d;
            });
            if (parseInt(e.perc) === 1) {
              //add % if not single
              radarEchartOptions.radar[0].indicator.push({
                color: "black",
                name: `%${e.name}`,
                max: 100,
              });
              radarEchartOptions.series[0].data.map((d) => {
                d.value.push(0);
                return d;
              });
            }
          }
        });
        radarEchartOptions.legend.data.push(`${payload.season.season_name}`);
        const seasonData = radarEchartOptions.radar[0].indicator.map((stat) => {
          const statidx = payload.seasonData.findIndex((ps) => {
            return ps.name === stat.name || `%${ps.name}` === `${stat.name}`;
          });
          if (statidx > -1) {
            //season have this stat
            return stat.name.indexOf("%") < 0
              ? parseFloat(payload.seasonData[statidx].x90min).toPrecision(3)
              : parseInt(payload.seasonData[statidx].perc) === 1
              ? (payload.seasonData[statidx].num_acc_succ * 100) /
                payload.seasonData[statidx].num_acc_jug
              : 0;
          } else return 0;
        });
        radarEchartOptions.series[0].data.push({
          value: seasonData,
          name: `${payload.season.season_name}`,
          itemStyle: {
            color: colors[radarEchartOptions.series[0].data.length],
          },
          areaStyle: {
            color: colors[radarEchartOptions.series[0].data.length],
          },
        });

        const seasonDataSets = payload.seasonData.map((pls) => {
          return pls.single === 0
            ? parseFloat(pls.x90min).toPrecision(4)
            : pls.name.indexOf("xg") === 0
            ? parseFloat(pls.num_acc_succ).toPrecision(4)
            : parseFloat(pls.num_acc_succ).toPrecision(4);
        });
        originalStats.push(seasonDataSets);
      }

      const exists = playerStats.filter((p) => {
        return payload.player.uid_pla === p.uid_pla;
      });
      if (exists.length < 1) {
        payload.player.active = true;
        playerStats.push(payload.player);
      }

      //check for each player stat if exists in current stats
      payload.playerData.forEach((e) => {
        const curidx = radarEchartOptions.radar[0].indicator.findIndex((es) => {
          return es.name === e.name;
        });
        if (curidx > -1) {
          //Exists, check if current value is lower and ensure is the same index
          if (
            parseFloat(radarEchartOptions.radar[0].indicator[curidx].max) <
            parseFloat(e.x90min)
          ) {
            if (parseFloat(e.x90min) < 1) {
              radarEchartOptions.radar[0].indicator[curidx].max =
                parseFloat(e.x90min) + 0.1;
            } else {
              radarEchartOptions.radar[0].indicator[curidx].max =
                parseFloat(e.x90min) + 1;
            }
          }
        } else {
          //Not exists indicator - Add the new stat, and new values as 0 for players we had before
          radarEchartOptions.radar[0].indicator.push({
            color: "black",
            name: e.name,
            max:
              parseFloat(e.x90min) < 1
                ? parseFloat(e.x90min) + 0.1
                : parseFloat(e.x90min) + 1,
          });
          radarEchartOptions.series[0].data.map((d) => {
            d.value.push(0);
            return d;
          });

          if (parseInt(e.single) === 0 && parseInt(e.perc) === 1) {
            //add % if not single
            radarEchartOptions.radar[0].indicator.push({
              color: "black",
              name: `%${e.name}`,
              max: 100,
            });

            radarEchartOptions.series[0].data.map((d) => {
              d.value.push(0);
              return d;
            });
          }
        }
      });

      /*for (let i = 0; i < payload.playerData.length; i++) {
        radarEchartOptions.radar[0].indicator = radarEchartOptions.radar[0].indicator.map(
          (stat) => {
            if (stat.name === payload.playerData[i].name) {
              if (
                parseFloat(stat.max) < parseFloat(payload.playerData[i].x90min)
              ) {
                console.log("Current is low");
                if (parseFloat(payload.playerData[i].x90min) < 1) {
                  stat.max = parseFloat(payload.playerData[i].x90min) + 0.1;
                } else {
                  stat.max = parseFloat(payload.playerData[i].x90min) + 1;
                }
              }
            } else {
              //This stat didn't exist in players before
            }
            return stat;
          }
        );
        const exists = radarEchartOptions.radar[0].indicator.filter((s) => {
          return s.name === payload.playerData[i].name;
        });
        if (exists.length === 0)
          radarEchartOptions.radar[0].indicator.push({
            color: "black",
            name: payload.playerData[i].name,
            max:
              parseFloat(payload.playerData[i].x90min) < 1
                ? parseFloat(payload.playerData[i].x90min) + 0.1
                : parseFloat(payload.playerData[i].x90min) + 1,
          });
      }*/
      radarEchartOptions.legend.data.push(
        `${payload.player.apodo}${
          payload.season.season_name
            ? "_" +
              payload.season.season_name +
              `${
                payload.fromDate
                  ? "_" + payload.fromDate.split("/").join("")
                  : ""
              }`
            : ""
        }`
      );

      //normalize players stats for have the same order
      const playerData = radarEchartOptions.radar[0].indicator.map((stat) => {
        const statidx = payload.playerData.findIndex((ps) => {
          return ps.name === stat.name || `%${ps.name}` === `${stat.name}`;
        });
        if (statidx > -1) {
          //player have this stat
          return stat.name.indexOf("%") < 0
            ? parseFloat(payload.playerData[statidx].x90min).toPrecision(3)
            : payload.playerData[statidx].perc === 1
            ? (payload.playerData[statidx].num_acc_succ * 100) /
              payload.playerData[statidx].num_acc_jug
            : 0;
        } else return 0;
      });
      radarEchartOptions.series[0].data.push({
        value: playerData,
        name: `${payload.player.apodo}${
          payload.season.season_name
            ? "_" +
              payload.season.season_name +
              `${
                payload.fromDate
                  ? "_" + payload.fromDate.split("/").join("")
                  : ""
              }`
            : ""
        }`,
        itemStyle: {
          color: colors[radarEchartOptions.series[0].data.length],
        },
        areaStyle: {
          color: colors[radarEchartOptions.series[0].data.length],
        },
      });
      /*apexChart.series.push({name:`${payload.player.apodo}${payload.season.season_name?'_'+payload.season.season_name:''}` ,data: payload.playerData.map((pls)=>{
                return parseFloat(pls.x90min).toPrecision(2)
            })})


            /*dataSets = dataSets.map((v)=>{
                return normalizeData(v,dataSets);
            })
            const rgba=getStatColor(tempRadar.datasets.length);

            tempRadar.datasets.push(
                {label:`${payload.player.apodo}${payload.season.season_name?'_'+payload.season.season_name:''}` ,
                    backgroundColor: rgba,
                    borderColor: rgba,
                    pointBackgroundColor: rgba,
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: rgba,
                    data:dataSets,
                    key:payload.player.uid_pla
                }
            );*/

      //Normalización
      //
      let dataSets = payload.playerData.map((pls) => {
        return pls.single === 0
          ? parseFloat(pls.x90min).toPrecision(4)
          : pls.name.indexOf("xg") === 0
          ? parseFloat(pls.num_acc_succ).toPrecision(4)
          : parseFloat(pls.num_acc_succ).toPrecision(4);
      });
      originalStats.push(dataSets);

      /*   let ds =[];
            for (let n=0;n<dataSets.length;n++){
                const el=[]
                for (let i=0;i<originalStats.length;i++){
                    el.push(originalStats[i][n])
                }
                ds.push(el);
            }


            if (ds[0].length>1)
                ds = ds.map((a)=>{
                    return a.map((ea)=>{
                        return normalizeData(ea,a)
                    })
                })


            let nds =[];
            for (let n=0;n<ds[0].length;n++){
                const el=[]
                for (let i=0;i<ds.length;i++){
                    el.push(ds[i][n])
                }
                nds.push(el);
            }


            tempRadar.datasets =tempRadar.datasets.map((tds,i)=>{
                tds.data=nds[i];
                return tds;
            })*/

      //Fin de Normalización

      dispatch({
        type: actions.GET_PREMIUM_PLAYER_STATS_SUCCESS,
        payload: { playerStats, originalStats, radarEchartOptions },
      });
    };
  },

  removePlayerStat: (uid_pla) => {
    return (dispatch, getState) => {
      let playerStats = cloneDeep(getState().Premium.playerStats).filter(
        (pl) => {
          return pl.uid_pla !== uid_pla;
        }
      );

      let playerRadar = cloneDeep(getState().Premium.playerRadar);
      playerRadar.datasets = playerRadar.datasets.filter((ds) => {
        return ds.key !== uid_pla;
      });

      let filteredPlayerRadar = cloneDeep(
        getState().Premium.filteredPlayerRadar
      );
      filteredPlayerRadar.datasets = filteredPlayerRadar.datasets.filter(
        (ds) => {
          return ds.key !== uid_pla;
        }
      );
      dispatch({
        type: actions.REMOVE_PLAYER_STAT,
        payload: { playerStats, playerRadar, filteredPlayerRadar },
      });
    };
  },

  changePlayerActiveStat: (uid_pla) => {
    return (dispatch, getState) => {
      let playerStats = cloneDeep(getState().Premium.playerStats).map((pl) => {
        pl.active =
          pl.uid_pla === uid_pla ? (pl.active = !pl.active) : pl.active;
        return pl;
      });
      let filteredPlayerRadar = cloneDeep(getState().Premium.playerRadar);

      filteredPlayerRadar.datasets = filteredPlayerRadar.datasets.filter(
        (ds) => {
          const isActiveRadar = playerStats.filter((ps) => {
            return ds.key === ps.uid_pla && ps.active === true;
          });
          return isActiveRadar.length > 0;
        }
      );

      dispatch({
        type: actions.CHANGE_ACTIVE_PLAYER_STAT,
        payload: { playerStats, filteredPlayerRadar },
      });
    };
  },

  getTeamPlayers: (team) => {
    return (dispatch, getState) => {
      const selectedSeason = getState().Premium.selectedSeason[0];
      const fed = getState().Premium.selected_type;
      const selectedCompetition = getState().Premium.selectedCompetition[0];
      const selectedGroup = getState().Premium.selectedGroup[0];
      const isFav = getState().Premium.favouriteTeams.filter((ft) => {
        return ft.uid_team === team.uid_team;
      });
      team.isFav = isFav.length;
      dispatch({
        type: actions.GET_PREMIUM_TEAM_PLAYERS,
        payload: {
          selectedCompetition,
          selectedGroup,
          selectedSeason,
          fed,
          team,
        },
      });
    };
  },

  getTeamPlayersSuccess: (teamPlayers) => {
    return (dispatch, getState) => {
      const favouritePlayers = getState().Premium.favouritePlayers;
      const tp = teamPlayers.map((p) => {
        const isFav = favouritePlayers.filter((fp) => {
          return fp.uid_pla === p.uid_pla;
        });
        p.isFav = isFav.length > 0 ? 1 : 0;
        return p;
      });
      dispatch({
        type: actions.GET_PREMIUM_TEAM_PLAYERS_SUCCESS,
        teamPlayers: tp,
      });
    };
  },

  getPremiumPlayerGames: () => {
    return (dispatch, getState) => {
      const fed = getState().Premium.selected_type;
      const selectedCompetition = getState().Premium.selectedCompetition;
      const selectedSeason = getState().Premium.selectedSeason;
      const selectedPlayer = getState().Premium.selectedPlayer;
      dispatch({
        type: actions.GET_PREMIUM_PLAYER_GAMES,
        payload: { selectedCompetition, selectedPlayer, selectedSeason, fed },
      });
    };
  },

  getPlayerGamesV2: (uid_fed, uid_pla) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GET_PREMIUM_PLAYER_GAMESV2,
        payload: { uid_fed, uid_pla },
      });
    };
  },

  getPremiumPlayerGamesSuccess: (playerGames) => ({
    type: actions.GET_PREMIUM_PLAYER_GAMES_SUCCESS,
    playerGames,
  }),

  getPremiumPlayerDetail: (uid_pla, uid_season, uid_fed) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_PREMIUM_PLAYER_DETAIL,
        payload: { uid_season, uid_pla, uid_fed },
      });
    };
  },

  getPremiumPlayerDetailSuccess: (playerDetail) => ({
    type: actions.GET_PREMIUM_PLAYER_DETAIL_SUCCESS,
    playerDetail,
  }),

  selectSeason: (selected_season) => {
    return (dispatch, getState) => {
      const selectedSeason = getState().Premium.competitions.filter((c) => {
        return c.uid_tem === selected_season;
      });
      const favouriteCompetitions = getState().Premium.favouriteCompetitions;
      const fed = getState().Premium.selected_type;
      const selectedCompetition = getState().Premium.selectedCompetition;
      dispatch({
        type: actions.SELECT_SEASON,
        payload: {
          selectedSeason,
          selectedCompetition,
          fed,
          favouriteCompetitions,
        },
      });
    };
  },
  selectCountry: (selected_country) => {
    return (dispatch, getState) => {
      const countryCompetitions = getState().Premium.competitions.filter(
        (c) => {
          return c.uid_country === selected_country;
        }
      );
      const selectedCountry = getState().Premium.countries.filter((c) => {
        return c.uid_country === selected_country;
      })[0];
      const filteredCompetitions = Array.from(
        new Set(countryCompetitions.map((c) => c.uid_com))
      ).map((uid) => {
        return {
          uid_com: uid,
          nom_com: countryCompetitions.find((c) => c.uid_com === uid).nom_com,
        };
      });
      dispatch({
        type: actions.SELECT_COUNTRY,
        payload: { selectedCountry, filteredCompetitions },
      });
    };
  },
  selectCompetition: (selected_competition) => {
    return (dispatch, getState) => {
      const selectedCompetition = getState().Premium.competitions.filter(
        (c) => {
          return c.uid_com === selected_competition;
        }
      );
      const seasons = Array.from(
        new Set(selectedCompetition.map((c) => c.uid_tem))
      ).map((uid) => {
        const com = selectedCompetition.find((c) => c.uid_tem === uid);
        return {
          uid_tem: uid,
          nom_tem: com.nom_tem,
        };
      });
      dispatch({
        type: actions.SELECT_COMPETITION,
        payload: { selectedCompetition, seasons },
      });
    };
  },
  selectGroup: (selected_group) => {
    return (dispatch, getState) => {
      const selectedGroup = getState().Premium.groups.filter((c) => {
        return c.uid_gru === selected_group;
      });
      dispatch({
        type: actions.SELECT_GROUP,
        payload: { selectedGroup },
      });
    };
  },
  selectPlayer: (selectedPlayer) => {
    return (dispatch) => {
      dispatch({
        type: actions.SELECT_PLAYER,
        payload: { selectedPlayer },
      });
    };
  },
  searchPlayer: (player_name) => {
    return (dispatch, getState) => {
      const fed = getState().Premium.selected_type;
      dispatch({
        type: actions.SEARCH_PLAYER,
        payload: { player_name, fed },
      });
    };
  },
  searchPlayersSuccess: (playerList) => {
    return (dispatch) => {
      dispatch({
        type: actions.SEARCH_PLAYER_SUCCESS,
        payload: { playerList },
      });
    };
  },
  globalSearch: (fed, player_name, team_name, years) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GLOBAL_SEARCH,
        payload: { player_name, fed, team_name, years },
      });
    };
  },
  globalSearchSuccess: (globalSearchResult) => {
    return (dispatch) => {
      dispatch({
        type: actions.GLOBAL_SEARCH_SUCCESS,
        payload: { globalSearchResult },
      });
    };
  },
  getAPIError: () => ({
    type: actions.API_PREMIUM_ERROR,
  }),
};
export default actions;
