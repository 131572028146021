const actions = {
  GET_FEDERATIONS: "GET_FEDERATIONS",
  GET_COMPETITIONS: "GET_COMPETITIONS",
  GET_GROUPS: "GET_GROUPS",
  GET_SEASONS: "GET_SEASONS",
  GET_STANDINGS: "GET_STANDINGS",
  GET_GROUP_GAMES: "GET_GROUP_GAMES",
  GET_OUTSTANDING_GROUP: "GET_OUTSTANDING_GROUP",
  GET_SQUAD: "GET_SQUAD",
  SELECT_PLAYER: "SELECT_PLAYER",
  SELECT_SQUAD: "SELECT_SQUAD",
  GET_PLAYERS_GAME: "GET_PLAYERS_GAME",
  API_ERROR: "API_ERROR",
  FEDERATIONS_SUCCESS: "FEDERATIONS_SUCCESS",
  COMPETITIONS_SUCCESS: "COMPETITIONS_SUCCESS",
  GROUPS_SUCCESS: "GROUPS_SUCCESS",
  GROUP_GAMES_SUCCESS: "GROUP_GAMES_SUCCESS",
  GAME_PLAYERS_SUCCESS: "GAME_PLAYERS_SUCCESS",
  OUTSTANDING_GROUP_SUCCESS: "OUTSTANDING_GROUP_SUCCESS",
  SEASONS_SUCCESS: "SEASONS_SUCCESS",
  STANDING_SUCCESS: "STANDING_SUCCESS",
  SQUAD_SUCCESS: "SQUAD_SUCCESS",

  GET_PREMIUM_TYPES: "GET_PREMIUM_TYPES",
  GET_PREMIUM_TYPES_SUCCESS: "GET_PREMIUM_TYPES_SUCCESS",
  SEARCH_PLAYERS_V2: "SEARCH_PLAYERS_V2",
  SEARCH_PLAYERS_V2_SUCCESS: "SEARCH_PLAYERS_V2_SUCCESS",
  SEARCH_TEAMS: "SEARCH_TEAMS",
  SEARCH_TEAMS_SUCCESS: "SEARCH_TEAMS_SUCCESS",

  searchTeams: (selected_fed, selected_season, team_name) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SEARCH_TEAMS,
        payload: { selected_fed, selected_season, team_name },
      });
    };
  },
  searchTeamsSuccess: (teams) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SEARCH_TEAMS_SUCCESS,
        payload: { teams },
      });
    };
  },

  searchFedederationPlayers: (player_name, team_name, selected_fed) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SEARCH_PLAYERS_V2,
        payload: { player_name, team_name, selected_fed },
      });
    };
  },
  searchFedPlayersSuccess: (players) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SEARCH_PLAYERS_V2_SUCCESS,
        payload: { players },
      });
    };
  },

  getFederations: () => ({
    type: actions.GET_FEDERATIONS,
  }),
  getCompetitions: (selected_tem) => {
    return (dispatch, getState) => {
      const selected_fed = getState().Competitions.selectedFederation;
      dispatch({
        type: actions.GET_COMPETITIONS,
        payload: { selected_tem, selected_fed },
      });
    };
  },
  getGroups: (selected_com) => {
    return (dispatch, getState) => {
      const selected_fed = getState().Competitions.selectedFederation;
      dispatch({
        type: actions.GET_GROUPS,
        payload: { selected_com, selected_fed },
      });
    };
  },
  getStandings: (selected_gru, com) => {
    return (dispatch, getState) => {
      const selected_fed = getState().Competitions.selectedFederation;
      const selected_com = getState().Competitions.selectedCompetition;
      dispatch({
        type: actions.GET_STANDINGS,
        payload: { selected_gru, selected_fed, selected_com, com },
      });
    };
  },
  getGroupGames: (selected_gru) => {
    return (dispatch, getState) => {
      const selected_fed = getState().Competitions.selectedFederation;
      const selected_com = getState().Competitions.selectedCompetition;
      dispatch({
        type: actions.GET_GROUP_GAMES,
        payload: { selected_gru, selected_fed, selected_com },
      });
    };
  },
  getOutstandingGroup: (selected_gru, pos) => {
    return (dispatch, getState) => {
      const selected_fed = getState().Competitions.selectedFederation;
      const selected_com = getState().Competitions.selectedCompetition;
      dispatch({
        type: actions.GET_OUTSTANDING_GROUP,
        payload: { selected_gru, selected_fed, selected_com, pos },
      });
    };
  },
  getSeasons: (selected_fed) => ({
    type: actions.GET_SEASONS,
    payload: { selected_fed },
  }),
  getSquad: (selected_team, selected_fed, selected_tem, selected_com) => {
    return (dispatch, getState) => {
      if (!selected_fed) {
        const selected_fed = getState().Competitions.selectedFederation;
        const selected_com = getState().Competitions.selectedCompetition;
        const selected_tem = getState().Competitions.selectedSeason;
        dispatch({
          type: actions.GET_SQUAD,
          payload: { selected_team, selected_fed, selected_tem, selected_com },
        });
      } else
        dispatch({
          type: actions.GET_SQUAD,
          payload: { selected_team, selected_fed, selected_tem, selected_com },
        });
    };
  },
  selectPlayer: (id) => ({
    type: actions.SELECT_PLAYER,
    id,
  }),
  selectSquad: (id) => ({
    type: actions.SELECT_SQUAD,
    id,
  }),
  getAPIError: () => ({
    type: actions.API_ERROR,
  }),
  getFederationsSuccess: (fed) => ({
    type: actions.FEDERATIONS_SUCCESS,
    fed,
  }),
  getCompetitionsSuccess: (com) => ({
    type: actions.COMPETITIONS_SUCCESS,
    com,
  }),
  getGroupsSuccess: (gru) => ({
    type: actions.GROUPS_SUCCESS,
    gru,
  }),
  getSeasonsSuccess: (seasons) => ({
    type: actions.SEASONS_SUCCESS,
    seasons,
  }),
  getStandingSuccess: (standing) => ({
    type: actions.STANDING_SUCCESS,
    standing,
  }),
  getGroupGamesSuccess: (games) => ({
    type: actions.GROUP_GAMES_SUCCESS,
    games,
  }),
  getOutstandingGroupSuccess: (outstanding) => ({
    type: actions.OUTSTANDING_GROUP_SUCCESS,
    outstanding,
  }),
  getSquadSuccess: (squad) => ({
    type: actions.SQUAD_SUCCESS,
    squad,
  }),
};
export default actions;
